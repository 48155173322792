import { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import Navbar from '../../components/navbar/navbar.component.js';
import Sidebar from '../../components/sidebar/sidebar.component.js';
import {NavigationOptions} from '../../utilities/navigationOptions';
import './dashboard.page.scss';
import HelpSideNavbar from '../../Awesome-ui/HelpSideNavbar/HelpSideNavbar';
import NotificationBar from '../../Awesome-ui/NotificationBar/NotificationBar.js';
import { getDataCount,getByRelationalID } from '../../services/cassandra-services.js';
import { updateContacts,updateContactCount } from '../../store/cassandra-slice.js';
import { getProfileDetails } from '../../services/onboarding-service.js';
import { updateProfileDetails } from '../../store/user-slice.js';
import { updateToast } from "../../store/toast-slice";
import { CodeAnalogy } from "../../components/toast/toast.component";

/**
 * Dashboard - the Page that consist Sidebar and Navbar
 * Things that changes are the Pagelets like - Contacts Component
 * @returns {HTMLElement}
 */
const Dashboard = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const token = useSelector((state) => state.user.token);
  const nav_bar_mode = useSelector((state) => state.ux.nav_bar_mode);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    if (token) {
      const contact_data_result = getByRelationalID();
      const count_result = getDataCount();
      const profile_details = getProfileDetails();
      const printPromiseJSON = async () => {
        const contacts = await contact_data_result;
        const count = await count_result;
        const profile = await profile_details;
        dispatch(updateContacts(contacts.data.model));
        dispatch(updateContactCount(count.data.model));
        dispatch(updateProfileDetails(profile.data.model));
      };
      printPromiseJSON();
    }
    else {
      navigate('/signin');
      dispatch(updateToast({
        code: CodeAnalogy.WARN, 
        title: "Please login to proceed!",
        message: ""
      }));
    }    
  },[]);

  return(
    <div className="dashboard-containter">
      {nav_bar_mode!=="TOP" ? <Sidebar /> : null}
      <div className="dashboard-content">
        <Navbar setOpenHelpDialog={setOpenHelpDialog} setOpenNotifications={setOpenNotifications}/>
        {NavigationOptions[location.pathname]}
      </div>
      <HelpSideNavbar helpOpen={openHelpDialog} closeSideBar={setOpenHelpDialog} />
      <NotificationBar
        filterOption
        showNotify={openNotifications}
        closeNotify={setOpenNotifications}
        listOfNotification={[]}
      />
    </div>
  )
}
export default Dashboard;