import { useSelector } from 'react-redux'
import {ReactComponent as MailChimp} from '../../assets/company-logo/mailchimp.svg';
import {ReactComponent as ContactList} from '../../assets/company-logo/contact-list.svg';
import './contact-body.scss'
import BorderListItem from '../../components/border-list-item/border-list-item.component';
import SendContactTableHead from '../../Awesome-ui/SendContactTableHead/SendContactTableHead';
import SendContactTableRow from '../../Awesome-ui/SendContactTableRow/SendContactTableRow';

/**
 * Contact Body : All Contacts Pagelet - Option 1 of Contact Page
 * @returns {HTMLElement}
 */
const ContactBodyAllContacts = () => {
  const contact_count = useSelector((state) => state.cassandra.count);
  const contacts = useSelector((state) => state.cassandra.contacts.Contacts);

  return(
    <div className="contact-body-container">
      <div className="dropdown-container">
        <div className="dropdown" />
      </div>
      <div className="search-bar-container">
        <div className="search-bar" />
      </div>
      {
        contacts ? 
        <div className='table-space-container'>
          <SendContactTableHead />
          <div className='table-content-scroll custom-scrollbar'>
          {
            contacts.map(item => (
              <SendContactTableRow key={item.id} data={item}/>
            ))
          }
          </div>
        </div>
        :
        <>
          <div className="body-header">
            <p className="body-title">👋Hey, John Doe</p>
            <p className="body-subtitle">Let's add some contacts!</p>
          </div>
          <div className="body-horizontal-list">
            <BorderListItem handleClick={() => alert("new contact")}>
              Create new contact
            </BorderListItem>
            <BorderListItem icon={<ContactList />} handleClick={() => alert("new contact action")}>
              Enter a list of contact
            </BorderListItem>
            <BorderListItem icon={<MailChimp />} handleClick={() => alert("new contact action")}>
              Import from MailChimp
            </BorderListItem>
          </div>
          <p className="body-text">
            {contact_count > 0 ? contact_count:'No'} 
            {contact_count > 1 ? ' Contacts ':' Contact '}
            Found</p>
          <p className="body-para">Once you add contacts, you'll be able to engage and send personalized emails to your contacts</p>
        </>
      }
    </div>
  )
}
export default ContactBodyAllContacts;