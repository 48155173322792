import "./TableRow.scss"

/**
 * This is Used in DeskTicketsCompactview Component
 *
 * For Table Rows
 *
 * @param {Object} ticket
 *
 */

const TableRow = ({onClick}) => {
    return (
        <div
            className="compactview-table-row"
            onClick={onClick}
        >
            <p>Contact List #1</p>
            <p>9</p>
            <p>Confirmed</p>
            <p>Jan 8, 22</p>
            <p>Daniel</p>
            <p>Jan 6, 22</p>
        </div>
    )
}

export default TableRow;