import { useState } from "react";
import NameNumber from "./name-number";
const ChooseName = ({chosenNumber}) => {
  const [name, setName] = useState('');
  const handleInput = (e) => {
    setName(e.target.value);
  }
  return(
    <div className="choose-name-container">
      {
        chosenNumber ? 
          <NameNumber 
            number={chosenNumber.friendly_name} 
            location={`${chosenNumber.locality}, ${chosenNumber.iso_country}`} 
            price={"$1.00"} 
            active={true}
          />
          :
          <p className="">Please select a valid number to proceed</p>
      }
      
      <div className="choose-name-input">
        <div className="micro-settings-container">
          <p className="ms-title">Symbol & name</p>
          <p className="ms-subtitle">Giving a name and symbol to number will help you and your team distinguish between all the numbers.</p>
          <div className="ms-field">
            <input
              type="text"
              placeholder="🏠"
              className="ms-symbol-input"
            />
            <input 
              type="text"
              placeholder="Number Name"
              className="ms-normal-input"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChooseName;