import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import "./homepage.pagelet.scss";

/**
 * Micro component of BorderBox div 
 */
const OutlineBox = React.memo(({title, large, small}) => {
  return(
    <div className={`outliner-box ${large ? 'large': ''} ${small ? 'small': ''}`}>
      <div className="drag-icon" />
      <p className="outline-title">{title}</p>
    </div>
  )
})

/**
 * Homepage the first dashboard pagelet
 */
const HomePage = React.memo(() => {
  return (
    <div className="homepage-container">
      <p className="outline-title">Subscribers</p>
      <OutlineBox title="" large={true}/>
      <DndProvider backend={HTML5Backend}>
        <div className="wrap-container">
          <div className="wrap-container">
            <OutlineBox title="Delivered" small={true}/>
            <OutlineBox title="Opens" small={true}/>
            <OutlineBox title="Clicks" small={true}/>
            <OutlineBox title="Replies" small={true}/>
          </div>
          <div className="wrap-big">
            <OutlineBox title="Segment Size" />
          </div>
        </div>
      </DndProvider>
      <div className="wrap-container">
        <div className="wrap-container">
          <OutlineBox title="Unsubscribed" small={true}/>
          <OutlineBox title="Skipped" small={true}/>
          <OutlineBox title="Bounced" small={true}/>
          <OutlineBox title="Spam Reported" small={true}/>
        </div>
        <div className="wrap-big">
          <OutlineBox title="Contact Source" />
        </div>
      </div>
    </div>
  )
})
export default HomePage;