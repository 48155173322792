import "./tag.component.scss";

const Tag = () => {
  return(
    <div className="tag-component">
      <p>has-connected-app</p>  
      <div className="tag-close">
        <p>X</p>
      </div>
    </div>
  )
}
export default Tag;