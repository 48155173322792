import React, {useState} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppSwitcher from '../../Awesome-ui/AppSwitcher';
import { NavigationOptionsList } from '../../utilities/navigationOptions';
import './sidebar.component.scss'

/**
 * Generates a Sidebar
 * @param {Array} navOptions - The array of navigation paths : TODO
 * @returns {HTMLElement}
 */
const Sidebar = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useSelector((state) => state.ux.theme);
  const nav_bar_mode = useSelector((state) => state.ux.nav_bar_mode);
  const [openAppSwitcher, setOpenAppSwitcher] = useState(false)

  const handleNavigateTo = (path) => {
    navigate(path);
  }
  const validatePathLocation = (path) => {
    return location.pathname === path;
  }

  return(
    <>
      {
        openAppSwitcher ? 
        <AppSwitcher
          openAppSwitcher={openAppSwitcher}
          setOpenAppSwitcher={setOpenAppSwitcher}
        />
        : null
      }
      <div className={`sidebar-container ${theme==="LIGHT"?'light':''}`}>
        <div className="sidebar-head" onClick={() => setOpenAppSwitcher(true)}></div>
        <div className={`sidebar-logo`} />
      {
        NavigationOptionsList.map((item,index) => (
          <div 
            key={index}
            onClick={() => handleNavigateTo(item.path)}
            className={`sidebar-item ${validatePathLocation(item.path) ? 'selected':''}`}>
            {nav_bar_mode==="NAMED" ? <p className="siderbar-item-text">{item.name}</p> : null}
          </div>
        ))
      }
      </div>
    </>
  )
})
export default Sidebar;