import React,{useState, useEffect} from 'react';
import ListView from './components/listView/ListView';
import CardView from './components/cardView/CardView';
import NewNumber from '../new-number/new-number.component';
import "./View.scss";

const PhoneNumberView = ({selectNumberToEdit}) => {
    const [listView,setListView] = useState(true);
    const [purchasedNumbers, setPurchasedNumbers] = useState([
        {
            "friendlyName": "two",
            "phoneNumber": "+15109849483",
            "countryCode": "US",
            "status": 1,
            "businessHours": "24x7"
        }
    ]);
    const [addNewNumber,setAddNewNumber] = useState(false);
    const [search, setSearch] = useState('')

    const toggleView = (e)=>{
        if(!e.target.classList.contains('active-view')){
            document.getElementsByClassName('active-view')[0].classList.remove('active-view');
            e.target.classList.add('active-view')
            setListView(!listView)
        }
       

    }
    const handleCloseAddNewNumber = () => {
        setAddNewNumber(false);
    }
    const handleOpenAddNewNumber = () => {
        setAddNewNumber(true);
    }

    useEffect(() => {
        // let listOfNumber = getListOfPurchasedNumbers();
        // listOfNumber.then(
        //     res => {
        //         setPurchasedNumbers(res.data.model)
        //     }
        // )
    },[])

    let purchasedNumbersList = purchasedNumbers;
    if (search) {
        purchasedNumbersList = purchasedNumbers.filter((number) => {
            return number.phoneNumber.includes(search) || number.friendlyName.toLowerCase().includes(search.toLowerCase())
        })
    }

    return (
        <div className="lv-container">
            <div className="togglediv">
                <div className="card-view toggleview flex-all" onClick={toggleView}>Card View</div>
                <div className="list-view toggleview flex-all active-view" onClick={toggleView}>List View</div>
            </div>
            <input className="lv-input" placeholder="Search for Phone number, Name, Area code..." onChange={(e) => setSearch(e.target.value)}/>
            {
                listView?
                <ListView purchasedNumbers={purchasedNumbersList} selectNumberToEdit={selectNumberToEdit} handleOpen={()=>handleOpenAddNewNumber()}/>
                :
                <CardView purchasedNumbers={purchasedNumbersList} selectNumberToEdit={selectNumberToEdit} handleOpen={()=>handleOpenAddNewNumber()}/>
            }
            {addNewNumber ? <div onClick={()=>handleCloseAddNewNumber()} className='backdrop-mask' /> : null}
            {addNewNumber ? <NewNumber handleClose={()=>handleCloseAddNewNumber()}/>:null}
        </div>
    )
}

export default PhoneNumberView;
