import { useState } from "react";
import ContactActivity from "../../components/contact-activity/contact-activity.component";
import "./contact-detail.pagelet.scss";
import ContactDetailForm from "../contact-detail-form/contact-detail-form.pagelet";
import BoxTabNavigation from "../../components/box-tab-navigation copy/box-tab-navigation.component";

/**
 * Contact Detail it the main Pagelet of the Contact Main Page 
 * @param {Object} data
 * @param {Array} optionsList 
 * @returns 
 */
const ContactDetail = ({ data, optionsList }) => {
  const [option,setOption] = useState(0);
  return(
    <div className="contact-detail-container">
      <div className="contact-detail-header">
        <BoxTabNavigation
          option={option} 
          setOption={setOption} 
          optionsList={optionsList}
        />
      </div>
      <div className="contact-detail-content">
        <ContactDetailForm />
        <ContactActivity data={data}/>
      </div>
    </div>
  )
}
export default ContactDetail;