import React from "react";

import "./DropDownOptions.scss";

/**
 * For Create Dropdown Options Use This DropDownOptions Component
 *
 * This is Used in DropdownContainer Component
 *
 * @param {Array} dropdownContent Array of Options
 * @param {Function} changeSelectedItem
 * @param {Function} setShowDropDown
 * @param {Boolean} showOnlyIconInSelect
 *
 */

const DropDownOptions = ({
  dropdownContent,
  changeSelectedItem,
  setShowDropDown,
  showOnlyIconInSelect,
}) => {
  return (
    <div className={`dropdown-options ${showOnlyIconInSelect && "right-0"}`}>
      {dropdownContent.map(({ id, name, component, iconComponent }) => (
        <div
          key={id}
          onClick={() => changeSelectedItem(id)}
          onBlur={() => setShowDropDown(false)}
        >
          {iconComponent ? iconComponent : null}
          {component && component}
          {name && <p>{name}</p>}
        </div>
      ))}
    </div>
  );
};

export default DropDownOptions;
