import { useState } from "react";
import {ReactComponent as Arrow} from './image/drop-arrow.svg';

import "./setting-activity-item.style.scss";

const SettingActivityItem = ({data}) => {
  const [expand,setExpand] = useState(false)
  const {title, content} = data;
  const handleArrowClick = () => {
    setExpand(!expand);
  }
  return(
    <div className="activity-item-container">
      <div className="activity-item-main-content">
        <div className="activity-item-main-left">
          <p className="activity-item-main-title">{title}</p>
        </div>

        <div className="activity-item-main-right">
          <div onClick={()=>handleArrowClick()} className={`activity-item-expand ${expand ? 'open':''}`}>
            <Arrow />
          </div>
        </div>       

      </div>
      {
        expand ? 
        <div className="activity-item-expand-content">
          {content}
        </div>
        : null
      }
    </div>
  )
}
export default SettingActivityItem;