import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nav_bar_mode: "SIDE",
  theme: "DARK",
}

export const UXSlice = createSlice({
  name: "UXSlice",
  initialState,
  reducers: {
    updateNavBarMode: (state, action) => {
      state.nav_bar_mode = action.payload;
    },
    updateTheme: (state, action) => {
      state.theme = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateNavBarMode,updateTheme } = UXSlice.actions

export default UXSlice.reducer