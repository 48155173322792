import ButtonSmall from '../button-small/button-small.component';
import ButtonWhite from '../button-white/button-white.component';
import TabNavigation from '../tab-navigation/tab-navigation.component';
import "./contact-header.component.scss";

/**
 * ContactHeader - Header Section of Contact Pagelet
 * @param {Number} option - for getting option of pagelet requested
 * @param {Function} setOption - for setting option
 * @param {Array} optionsList - for proving list of options
 * @returns {HTMLElement}
 */
const ContactHeader = ({option,setOption,optionsList}) => {
  const handleEditClick = () => {
    alert("Edit Button")
  }
  const handleImportClick = () => {
    alert("Import Button")
  }
  const handleAddContactClick = () => {
    alert("Add Contact Button")
  }
  return(
    <div className="contact-header">
      <div className="header-left">
        <p className="header-title">Contacts</p>
        <div className="header-nav">
          <TabNavigation option={option} setOption={setOption} optionsList={optionsList}/>
        </div>
      </div>
      <div className="header-right">
        <ButtonSmall selected={true} handleClick={handleEditClick}>
          Edit
        </ButtonSmall>
        <ButtonSmall handleClick={handleImportClick}>
          Import
        </ButtonSmall>
        <ButtonWhite blue={true} handleClick={handleAddContactClick}>
          Add Contact
        </ButtonWhite>
      </div>
    </div>
  )
}
export default ContactHeader;