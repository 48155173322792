import React from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavigationOptionsList } from '../../utilities/navigationOptions';
import RightNavbarButtons from '../right-navbar-buttons/right-navbar-buttons.component';
import './navbar.component.scss'

/**
 * Navbar - The Horizontal Top Bar 
 * @returns {HTMLElement}
 */
const Navbar = React.memo(({setOpenHelpDialog,setOpenNotifications}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useSelector((state) => state.ux.theme);
  const nav_bar_mode = useSelector((state) => state.ux.nav_bar_mode);
  
  const handleNavigateTo = (path) => {
    navigate(path);
  }
  const validatePathLocation = (path) => {
    return location.pathname === path;
  }
  return(
    <div className={`navbar-container ${theme==="LIGHT"?'light':''}`}>
    {
      nav_bar_mode==="TOP" ?
      <div className="navbar-options">
      {
        NavigationOptionsList.map((item,index) => (
          <div 
            key={index} 
            onClick={() => handleNavigateTo(item.path)}
            className={`navbar-item ${validatePathLocation(item.path) ? 'selected':''}`}>
            <p className="navbar-item-text">{item.name}</p>
          </div>
        ))
      }
      </div>
      : null
    }
      <RightNavbarButtons setOpenHelpDialog={setOpenHelpDialog} setOpenNotifications={setOpenNotifications}/>
    </div>
  )
})
export default Navbar;