import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {ReactComponent as Illustration} from '../../assets/illustration/404.svg';
import ButtonSmall from '../../components/button-small/button-small.component';
import Button from '../../components/button/button.component';
import "./404-page.page.scss";
import { revokeBearerToken } from "../../store/user-slice";
import { updateToast } from "../../store/toast-slice";
import { CodeAnalogy } from "../../components/toast/toast.component";

const Page404 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoToHome = () => {
    navigate('/');
  }
  const handleLogOut = () => {
    dispatch(revokeBearerToken());
    dispatch(updateToast({
      code: CodeAnalogy.SUCCESS, 
      title: "Logged out successfully.",
      message: ""
    }));
    navigate('/signin');
  }

  return(
    <div className="_404-page-container">
      <p className="_404-page-title">We can’t find what you’re looking for</p>
      <p className="_404-page-subtitle">Lemme get you out of this place...</p>
      <Illustration />
      <div className="_404-page-goback-container">
        <Button handleClick={() => handleGoToHome()}>Go to Home</Button>
      </div>  
      <div className="_404-page-logout-container">
        <p>OR</p>
        <ButtonSmall handleClick={() => handleLogOut()}>Log Out</ButtonSmall>
      </div>
    </div>
  )
}
export default Page404;