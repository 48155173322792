import PhoneNumberSettings from "./micro-components/phone-number";
import IntegrationsSetting from "./micro-components/integrations";
import Customise from "./micro-components/customise";

export const SettingsData = [
  {
    title: "User Settings",
    subtitle: "Configure the basic personal settings",
    settings: [
      {
        id: 0,
        name: "Profile",
        img: '',
        path: 'profile'
      },
      {
        id: 1,
        name: "Notification",
        img: '',
        path: 'notification'
      },
      {
        id: 2,
        name: "Phone Numbers",
        img: '',
        path: 'numbers'
      },
      {
        id: 3,
        name: "Integrations",
        img: '',
        path: 'integrations'
      }
    ]
  },
  {
    title: "Appearance",
    subtitle: "Configure the basic visual appearance",
    settings: [
      {
        id: 4,
        name: "Customize",
        img: '',
        path: 'customize'
      }
    ]
  },
]

export const SettingsMicroPages = {
  'profile': null,
  'integrations': <IntegrationsSetting />,
  'numbers': <PhoneNumberSettings />,
  'customize': <Customise />,
}