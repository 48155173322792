import IntialsProfilePicGenerator from "../../../Awesome-ui/InitialsProfilePicGenerator/InitialsProfilePicGenerator";
import "./micro-component.style.scss";
const ManageTeamsSettings = () => {
  return(
    <div className="micro-settings-container">
      <p className="ms-title">Share Access</p>
      <p className="ms-subtitle">Give other agents access to the phone number. Every agent who has access to the phone number can, see calls and messages, and receive incoming calls.</p>
      <div className="choose-user-list">
      {
        members.map((item,index) => (
          <div className="choose-user-item" key={index}>
            <div className="choose-user-left">
              <div className="choose-user-pic">
                <IntialsProfilePicGenerator name={item.name} />
              </div>
              <p className="choose-user-name">
                {item.name}
              </p>
            </div>
            <div className={`status ${item.status === "Owner" ? 'owner':''}`}>
              <p className="status-text">{item.status}</p>
            </div>
          </div>
        ))
      }
        <p className="choose-user-add-text">
          + Add a team member
        </p>
      </div>
    </div>
  )
}
export default ManageTeamsSettings;

const members = [
  {
    name: "Harry Potter",
    status: "Owner"
  },
  {
    name: "Hermione Granger",
    status: "Member"
  },
  {
    name: "Draco Malfoy",
    status: "Member"
  },
]