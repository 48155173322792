import ChooseLocation from "./micro-components/choose-location";
import ChooseName from "./micro-components/choose-name";
import "./new-number-item.component.scss";

const NewNumberItem = ({step, setStep, item, chosenNumber, setChosenNumber}) => {
  const {step_id, title, icon} = item;
  const handleOnClick = () => {
    setStep(step_id);
  }
  return(
    <div onClick={()=>handleOnClick()} className={`new-number-item-container ${step===step_id ? 'active-step':''}`}>
      <div className={`new-number-title ${step===step_id ? 'active-step-title':''}`}>
        {icon}
        <p className='new-number-title-text'>
        {title}
        </p>
      </div>
      {
        step===step_id ? 
        <div className="new-number-item-content">
          {
            step===1 ?
            <>
              <ChooseLocation chosenNumber={chosenNumber} setChosenNumber={setChosenNumber}/>
            </>: null
          }
          {
            step===2 ?
            <>
              <ChooseName chosenNumber={chosenNumber} />
            </>: null
          }
        </div>
        : null
      }
    </div>
  )
}
export default NewNumberItem;