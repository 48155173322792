import TopArrow from "./images/TopArrow.svg"
import DownArrow from "./images/DownArrow.svg"
import "./CompactviewTableCell.scss"

/**
 * This is Used in DeskTicketsCompactviewHead Component
 *
 * For Table Header Cells
 *
 * @param {Object} tablecell Contain cell name , style etc
 *  @param {Function} sortContent Function for sort TableRows
 *
 */

const CompactviewTableCell = ({ tablecell, sortContent }) => {
    const { name, sortingArrows, nameStyle } = tablecell

    return (
        <div className="compactview-table-cell">
            <p style={nameStyle}>{name}</p>
            {sortingArrows && (
                <div onClick={() => sortContent && sortContent(name)}>
                    <img src={TopArrow} alt="" />
                    <img src={DownArrow} alt="" />
                </div>
            )}
        </div>
    )
}

export default CompactviewTableCell
