import axios from 'axios';
import config from '../utilities/api-config';
import { store } from '../store/store';

const onboardingInstance = axios.create({
  baseURL: config.ONBOARDING_BASE_URL,
})

/**
 * Sign In to fetch Bearer Token
 * @param {Object} props: {email: ---, password: ---}
 */

export const signInUser = async (props) => {
  return await onboardingInstance
    .get(config.ONBOARDING_LOGIN_ENDPOINT, {
      params: {
        email: props.email,
        password: props.password
      }
    })
}

/**
 * Get User Profile Details
 * @param {String} token - got from successfull signin
 */
export const getProfileDetails = async () => {
  const token = store.getState().user.token;
  return await onboardingInstance
    .get(config.ONBOARDING_PROFILE_ENDPOINT, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
}