import React from "react"

// import { ReactComponent as SignIcon } from "Assets/ProductIcon/signicon.svg"
// import { ReactComponent as SocialIcon } from "Assets/ProductIcon/social/social-icon-1.svg";

/**
 * Make a entry here for all our products
 */
export const productMap = {
    1: {
        name: "Social",
        description: "Social Media Management",
        url: "https://obliqlabsapp.netlify.app",
        // Icon: <SocialIcon />,
        Icon: <div style={{backgroundColor: "pink", height: "50px", width: "50px", borderRadius: "5px"}}/>
    },
    2: {
        name: "Sign",
        description: "Digital Signature",
        url: "https://obliqlabsapp.netlify.app",
        // Icon: <SignIcon />,
        Icon: <div style={{backgroundColor: "pink", height: "50px", width: "50px", borderRadius: "5px"}}/>
    },
}
