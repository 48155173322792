import React,{useState} from "react"
import SideBarHeader from "../SideBarHeader/SideBarHeader"
import DropdownContainer from "../DropdownContainer/DropdownContainer"
import NotificationContainer from "../NotificationContainer/NotificationContainer"
import NoNotificationContainer from "../NoNotificationContainer/NoNotificationContainer"
import "./NotificationBar.scss"

/**
 *  Notification Bar
 *  @description This is the root notification component it consumes these components :
 *
 * - {@link SideBarHeader} Side bar component for the wrapper.
 * - {@link DropdownContainer} for any drop down or filter option.
 * - {@link NoNotificationContainer} when there is no notifications.
 * - {@link NotificationContainer} main notification component for representing one notification.
 *
 * @param {*} param0 { showNotify, closeNotify } for controlling the open close
 */

const NotificationBar = ({
    showNotify,
    closeNotify,
    filterOption,
    listOfNotification,
}) => {
    const [notifcation, setNotifcation] = useState(1)
    return showNotify ? (
        <div className="notification-bar">
            <SideBarHeader
                setShowBar={closeNotify}
                title="Notifications"
                color="#00A5BC"
            />
            {filterOption && (
                <div className="notification-dropdown">
                    <DropdownContainer
                        dropdown={notificationDropDown}
                        selectedValueId={notifcation}
                        updateSelectedValueId={setNotifcation}
                        selectedItemColor="#0091B6"
                    />
                </div>
            )}
            {listOfNotification && listOfNotification.length > 0 ? (
                <div className="notifications">
                    {listOfNotification.map((notification, index) => (
                        <NotificationContainer key={index} {...notification} />
                    ))}
                </div>
            ) : (
                <NoNotificationContainer />
            )}
        </div>
    ) : null
}

export default NotificationBar

const notificationDropDown = {
    dropdownContent: [
        {
            id: 1,
            name: "All",
        },
        {
            id: 2,
            name: "Assigned to me",
        },
        {
            id: 3,
            name: "Product update",
        },
    ],
}
