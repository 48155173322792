import "./micro-component.style.scss";
const PreferencesSettings = () => {
  return(
    <div className="micro-settings-container">
      <p className="ms-title">Number Preferences</p>
      <p className="ms-subtitle">Giving a name and symbol to number will help you and your team distinguish between all the numbers.</p>

      <p className="ms-title margin">Inbound Calls</p>
      <div className="ms-preference-list">
        <div className="ms-checkbox-item">
          <input type="checkbox" checked />
          <p>Start recording automatically</p>
        </div>
        <div className="ms-checkbox-item">
          <input type="checkbox" checked />
          <p>Send calls to voicemail outside business hours</p>
        </div>
        
      </div>
      <p className="ms-title margin">Outgoing Calls</p>
      <div className="ms-preference-list">
        <div className="ms-checkbox-item">
          <input type="checkbox" checked />
          <p>Start recording automatically</p>
        </div>
      </div>
    </div>
  )
}
export default PreferencesSettings;