import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription: null,
  subscriptionLoading: false,
}

export const SubscriptionSlice = createSlice({
  name: "SubscriptionSlice",
  initialState,
  reducers: {
    loadSubscriptionDetails: (state, action) => {
      state.subscription = action.payload.subscription;
      state.subscriptionLoading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateContacts,updateContactCount } = SubscriptionSlice.actions

export default SubscriptionSlice.reducer