import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  code: '',
  title: '',
  message: ''
}

export const ToastSlice = createSlice({
  name: "ToastSlice",
  initialState,
  reducers: {
    updateToast: (state, action) => {
      state.code = action.payload.code;
      state.title = action.payload.title;
      state.message = action.payload.message;
    },
    revokeToast: (state) => {
      state.code = '';
      state.title = '';
      state.message = '';
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateToast,revokeToast } = ToastSlice.actions

export default ToastSlice.reducer