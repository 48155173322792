import {ReactComponent as AC} from './flags/ac.svg';
import {ReactComponent as AD} from './flags/ad.svg';
import {ReactComponent as AE} from './flags/ae.svg';
import {ReactComponent as AF} from './flags/af.svg';
import {ReactComponent as AG} from './flags/ag.svg';
import {ReactComponent as AI} from './flags/ai.svg';
import {ReactComponent as AL} from './flags/al.svg';
import {ReactComponent as AM} from './flags/am.svg';
import {ReactComponent as AO} from './flags/ao.svg';
import {ReactComponent as AQ} from './flags/aq.svg';
import {ReactComponent as AR} from './flags/ar.svg';
import {ReactComponent as AS} from './flags/as.svg';
import {ReactComponent as AT} from './flags/at.svg';
import {ReactComponent as AU} from './flags/au.svg';
import {ReactComponent as AW} from './flags/aw.svg';
import {ReactComponent as AX} from './flags/ax.svg';
import {ReactComponent as AZ} from './flags/az.svg';
import {ReactComponent as BA} from './flags/ba.svg';
import {ReactComponent as BB} from './flags/bb.svg';
import {ReactComponent as BD} from './flags/bd.svg';
import {ReactComponent as BE} from './flags/be.svg';
import {ReactComponent as BF} from './flags/bf.svg';
import {ReactComponent as BG} from './flags/bg.svg';
import {ReactComponent as BH} from './flags/bh.svg';
import {ReactComponent as BI} from './flags/bi.svg';
import {ReactComponent as BJ} from './flags/bj.svg';
import {ReactComponent as BL} from './flags/bl.svg';
import {ReactComponent as BM} from './flags/bm.svg';
import {ReactComponent as BN} from './flags/bn.svg';
import {ReactComponent as BO} from './flags/bo.svg';
import {ReactComponent as BQ} from './flags/bq.svg';
import {ReactComponent as BR} from './flags/br.svg';
import {ReactComponent as BS} from './flags/bs.svg';
import {ReactComponent as BT} from './flags/bt.svg';
import {ReactComponent as BV} from './flags/bv.svg';
import {ReactComponent as BW} from './flags/bw.svg';
import {ReactComponent as BY} from './flags/by.svg';
import {ReactComponent as BZ} from './flags/bz.svg';
import {ReactComponent as CA} from './flags/ca.svg';
import {ReactComponent as CC} from './flags/cc.svg';
import {ReactComponent as CD} from './flags/cd.svg';
import {ReactComponent as CF} from './flags/cf.svg';
import {ReactComponent as CG} from './flags/cg.svg';
import {ReactComponent as CH} from './flags/ch.svg';
import {ReactComponent as CI} from './flags/ci.svg';
import {ReactComponent as CK} from './flags/ck.svg';
import {ReactComponent as CL} from './flags/cl.svg';
import {ReactComponent as CM} from './flags/cm.svg';
import {ReactComponent as CN} from './flags/cn.svg';
import {ReactComponent as CO} from './flags/co.svg';
import {ReactComponent as CP} from './flags/cp.svg';
import {ReactComponent as CR} from './flags/cr.svg';
import {ReactComponent as CU} from './flags/cu.svg';
import {ReactComponent as CV} from './flags/cv.svg';
import {ReactComponent as CW} from './flags/cw.svg';
import {ReactComponent as CX} from './flags/cx.svg';
import {ReactComponent as CY} from './flags/cy.svg';
import {ReactComponent as CZ} from './flags/cz.svg';
import {ReactComponent as DE} from './flags/de.svg';
import {ReactComponent as DG} from './flags/dg.svg';
import {ReactComponent as DJ} from './flags/dj.svg';
import {ReactComponent as DK} from './flags/dk.svg';
import {ReactComponent as DM} from './flags/dm.svg';
import {ReactComponent as DO} from './flags/do.svg';
import {ReactComponent as DZ} from './flags/dz.svg';
import {ReactComponent as EA} from './flags/ea.svg';
import {ReactComponent as EC} from './flags/ec.svg';
import {ReactComponent as EE} from './flags/ee.svg';
import {ReactComponent as EG} from './flags/eg.svg';
import {ReactComponent as EH} from './flags/eh.svg';
import {ReactComponent as ER} from './flags/er.svg';
import {ReactComponent as ESCT} from './flags/es-ct.svg';
import {ReactComponent as ESGA} from './flags/es-ga.svg';
import {ReactComponent as ES} from './flags/es.svg';
import {ReactComponent as ET} from './flags/et.svg';
import {ReactComponent as EU} from './flags/eu.svg';
import {ReactComponent as FI} from './flags/fi.svg';
import {ReactComponent as FJ} from './flags/fj.svg';
import {ReactComponent as FK} from './flags/fk.svg';
import {ReactComponent as FM} from './flags/fm.svg';
import {ReactComponent as FO} from './flags/fo.svg';
import {ReactComponent as FR} from './flags/fr.svg';
import {ReactComponent as GA} from './flags/ga.svg';
import {ReactComponent as GBENG} from './flags/gb-eng.svg';
import {ReactComponent as GBNIR} from './flags/gb-nir.svg';
import {ReactComponent as GBSCT} from './flags/gb-sct.svg';
import {ReactComponent as GBWLS} from './flags/gb-wls.svg';
import {ReactComponent as GB} from './flags/gb.svg';
import {ReactComponent as GD} from './flags/gd.svg';
import {ReactComponent as GE} from './flags/ge.svg';
import {ReactComponent as GF} from './flags/gf.svg';
import {ReactComponent as GG} from './flags/gg.svg';
import {ReactComponent as GH} from './flags/gh.svg';
import {ReactComponent as GI} from './flags/gi.svg';
import {ReactComponent as GL} from './flags/gl.svg';
import {ReactComponent as GM} from './flags/gm.svg';
import {ReactComponent as GN} from './flags/gn.svg';
import {ReactComponent as GP} from './flags/gp.svg';
import {ReactComponent as GQ} from './flags/gq.svg';
import {ReactComponent as GR} from './flags/gr.svg';
import {ReactComponent as GS} from './flags/gs.svg';
import {ReactComponent as GT} from './flags/gt.svg';
import {ReactComponent as GU} from './flags/gu.svg';
import {ReactComponent as GW} from './flags/gw.svg';
import {ReactComponent as GY} from './flags/gy.svg';
import {ReactComponent as HK} from './flags/hk.svg';
import {ReactComponent as HM} from './flags/hm.svg';
import {ReactComponent as HN} from './flags/hn.svg';
import {ReactComponent as HR} from './flags/hr.svg';
import {ReactComponent as HT} from './flags/ht.svg';
import {ReactComponent as HU} from './flags/hu.svg';
import {ReactComponent as IC} from './flags/ic.svg';
import {ReactComponent as ID} from './flags/id.svg';
import {ReactComponent as IE} from './flags/ie.svg';
import {ReactComponent as IL} from './flags/il.svg';
import {ReactComponent as IM} from './flags/im.svg';
import {ReactComponent as IN} from './flags/in.svg';
import {ReactComponent as IO} from './flags/io.svg';
import {ReactComponent as IQ} from './flags/iq.svg';
import {ReactComponent as IR} from './flags/ir.svg';
import {ReactComponent as IS} from './flags/is.svg';
import {ReactComponent as IT} from './flags/it.svg';
import {ReactComponent as JE} from './flags/je.svg';
import {ReactComponent as JM} from './flags/jm.svg';
import {ReactComponent as JO} from './flags/jo.svg';
import {ReactComponent as JP} from './flags/jp.svg';
import {ReactComponent as KE} from './flags/ke.svg';
import {ReactComponent as KG} from './flags/kg.svg';
import {ReactComponent as KH} from './flags/kh.svg';
import {ReactComponent as KI} from './flags/ki.svg';
import {ReactComponent as KM} from './flags/km.svg';
import {ReactComponent as KN} from './flags/kn.svg';
import {ReactComponent as KP} from './flags/kp.svg';
import {ReactComponent as KR} from './flags/kr.svg';
import {ReactComponent as KW} from './flags/kw.svg';
import {ReactComponent as KY} from './flags/ky.svg';
import {ReactComponent as KZ} from './flags/kz.svg';
import {ReactComponent as LA} from './flags/la.svg';
import {ReactComponent as LB} from './flags/lb.svg';
import {ReactComponent as LC} from './flags/lc.svg';
import {ReactComponent as LI} from './flags/li.svg';
import {ReactComponent as LK} from './flags/lk.svg';
import {ReactComponent as LR} from './flags/lr.svg';
import {ReactComponent as LS} from './flags/ls.svg';
import {ReactComponent as LT} from './flags/lt.svg';
import {ReactComponent as LU} from './flags/lu.svg';
import {ReactComponent as LV} from './flags/lv.svg';
import {ReactComponent as LY} from './flags/ly.svg';
import {ReactComponent as MA} from './flags/ma.svg';
import {ReactComponent as MC} from './flags/mc.svg';
import {ReactComponent as MD} from './flags/md.svg';
import {ReactComponent as ME} from './flags/me.svg';
import {ReactComponent as MF} from './flags/mf.svg';
import {ReactComponent as MG} from './flags/mg.svg';
import {ReactComponent as MH} from './flags/mh.svg';
import {ReactComponent as MK} from './flags/mk.svg';
import {ReactComponent as ML} from './flags/ml.svg';
import {ReactComponent as MM} from './flags/mm.svg';
import {ReactComponent as MN} from './flags/mn.svg';
import {ReactComponent as MO} from './flags/mo.svg';
import {ReactComponent as MP} from './flags/mp.svg';
import {ReactComponent as MQ} from './flags/mq.svg';
import {ReactComponent as MR} from './flags/mr.svg';
import {ReactComponent as MS} from './flags/ms.svg';
import {ReactComponent as MT} from './flags/mt.svg';
import {ReactComponent as MU} from './flags/mu.svg';
import {ReactComponent as MV} from './flags/mv.svg';
import {ReactComponent as MW} from './flags/mw.svg';
import {ReactComponent as MX} from './flags/mx.svg';
import {ReactComponent as MY} from './flags/my.svg';
import {ReactComponent as MZ} from './flags/mz.svg';
import {ReactComponent as NA} from './flags/na.svg';
import {ReactComponent as NC} from './flags/nc.svg';
import {ReactComponent as NE} from './flags/ne.svg';
import {ReactComponent as NF} from './flags/nf.svg';
import {ReactComponent as NG} from './flags/ng.svg';
import {ReactComponent as NI} from './flags/ni.svg';
import {ReactComponent as NL} from './flags/nl.svg';
import {ReactComponent as NO} from './flags/no.svg';
import {ReactComponent as NP} from './flags/np.svg';
import {ReactComponent as NR} from './flags/nr.svg';
import {ReactComponent as NU} from './flags/nu.svg';
import {ReactComponent as NZ} from './flags/nz.svg';
import {ReactComponent as OM} from './flags/om.svg';
import {ReactComponent as PA} from './flags/pa.svg';
import {ReactComponent as PE} from './flags/pe.svg';
import {ReactComponent as PF} from './flags/pf.svg';
import {ReactComponent as PG} from './flags/pg.svg';
import {ReactComponent as PH} from './flags/ph.svg';
import {ReactComponent as PK} from './flags/pk.svg';
import {ReactComponent as PL} from './flags/pl.svg';
import {ReactComponent as PM} from './flags/pm.svg';
import {ReactComponent as PN} from './flags/pn.svg';
import {ReactComponent as PR} from './flags/pr.svg';
import {ReactComponent as PS} from './flags/ps.svg';
import {ReactComponent as PT} from './flags/pt.svg';
import {ReactComponent as PW} from './flags/pw.svg';
import {ReactComponent as PY} from './flags/py.svg';
import {ReactComponent as QA} from './flags/qa.svg';
import {ReactComponent as RE} from './flags/re.svg';
import {ReactComponent as RO} from './flags/ro.svg';
import {ReactComponent as RS} from './flags/rs.svg';
import {ReactComponent as RU} from './flags/ru.svg';
import {ReactComponent as RW} from './flags/rw.svg';
import {ReactComponent as SA} from './flags/sa.svg';
import {ReactComponent as SB} from './flags/sb.svg';
import {ReactComponent as SC} from './flags/sc.svg';
import {ReactComponent as SD} from './flags/sd.svg';
import {ReactComponent as SE} from './flags/se.svg';
import {ReactComponent as SG} from './flags/sg.svg';
import {ReactComponent as SH} from './flags/sh.svg';
import {ReactComponent as SI} from './flags/si.svg';
import {ReactComponent as SJ} from './flags/sj.svg';
import {ReactComponent as SK} from './flags/sk.svg';
import {ReactComponent as SL} from './flags/sl.svg';
import {ReactComponent as SM} from './flags/sm.svg';
import {ReactComponent as SN} from './flags/sn.svg';
import {ReactComponent as SO} from './flags/so.svg';
import {ReactComponent as SR} from './flags/sr.svg';
import {ReactComponent as SS} from './flags/ss.svg';
import {ReactComponent as ST} from './flags/st.svg';
import {ReactComponent as SV} from './flags/sv.svg';
import {ReactComponent as SX} from './flags/sx.svg';
import {ReactComponent as SY} from './flags/sy.svg';
import {ReactComponent as SZ} from './flags/sz.svg';
import {ReactComponent as TA} from './flags/ta.svg';
import {ReactComponent as TC} from './flags/tc.svg';
import {ReactComponent as TD} from './flags/td.svg';
import {ReactComponent as TF} from './flags/tf.svg';
import {ReactComponent as TG} from './flags/tg.svg';
import {ReactComponent as TH} from './flags/th.svg';
import {ReactComponent as TJ} from './flags/tj.svg';
import {ReactComponent as TK} from './flags/tk.svg';
import {ReactComponent as TL} from './flags/tl.svg';
import {ReactComponent as TM} from './flags/tm.svg';
import {ReactComponent as TN} from './flags/tn.svg';
import {ReactComponent as TO} from './flags/to.svg';
import {ReactComponent as TR} from './flags/tr.svg';
import {ReactComponent as TT} from './flags/tt.svg';
import {ReactComponent as TV} from './flags/tv.svg';
import {ReactComponent as TW} from './flags/tw.svg';
import {ReactComponent as TZ} from './flags/tz.svg';
import {ReactComponent as UA} from './flags/ua.svg';
import {ReactComponent as UG} from './flags/ug.svg';
import {ReactComponent as UM} from './flags/um.svg';
import {ReactComponent as UN} from './flags/un.svg';
import {ReactComponent as US} from './flags/us.svg';
import {ReactComponent as UY} from './flags/uy.svg';
import {ReactComponent as UZ} from './flags/uz.svg';
import {ReactComponent as VA} from './flags/va.svg';
import {ReactComponent as VC} from './flags/vc.svg';
import {ReactComponent as VE} from './flags/ve.svg';
import {ReactComponent as VG} from './flags/vg.svg';
import {ReactComponent as VI} from './flags/vi.svg';
import {ReactComponent as VN} from './flags/vn.svg';
import {ReactComponent as VU} from './flags/vu.svg';
import {ReactComponent as WF} from './flags/wf.svg';
import {ReactComponent as WS} from './flags/ws.svg';
import {ReactComponent as XK} from './flags/xk.svg';
import {ReactComponent as XX} from './flags/xx.svg';
import {ReactComponent as YE} from './flags/ye.svg';
import {ReactComponent as YT} from './flags/yt.svg';
import {ReactComponent as ZA} from './flags/za.svg';
import {ReactComponent as ZM} from './flags/zm.svg';
import {ReactComponent as ZW} from './flags/zw.svg';

const CountryFlagSVG = Object.freeze({
  "AC": <AC />,
  "AD": <AD />,
  "AE": <AE />,
  "AF": <AF />,
  "AG": <AG />,
  "AI": <AI />,
  "AL": <AL />,
  "AM": <AM />,
  "AO": <AO />,
  "AQ": <AQ />,
  "AR": <AR />,
  "AS": <AS />,
  "AT": <AT />,
  "AU": <AU />,
  "AW": <AW />,
  "AX": <AX />,
  "AZ": <AZ />,
  "BA": <BA />,
  "BB": <BB />,
  "BD": <BD />,
  "BE": <BE />,
  "BF": <BF />,
  "BG": <BG />,
  "BH": <BH />,
  "BI": <BI />,
  "BJ": <BJ />,
  "BL": <BL />,
  "BM": <BM />,
  "BN": <BN />,
  "BO": <BO />,
  "BQ": <BQ />,
  "BR": <BR />,
  "BS": <BS />,
  "BT": <BT />,
  "BV": <BV />,
  "BW": <BW />,
  "BY": <BY />,
  "BZ": <BZ />,
  "CA": <CA />,
  "CC": <CC />,
  "CD": <CD />,
  "CF": <CF />,
  "CG": <CG />,
  "CH": <CH />,
  "CI": <CI />,
  "CK": <CK />,
  "CL": <CL />,
  "CM": <CM />,
  "CN": <CN />,
  "CO": <CO />,
  "CP": <CP />,
  "CR": <CR />,
  "CU": <CU />,
  "CV": <CV />,
  "CW": <CW />,
  "CX": <CX />,
  "CY": <CY />,
  "CZ": <CZ />,
  "DE": <DE />,
  "DG": <DG />,
  "DJ": <DJ />,
  "DK": <DK />,
  "DM": <DM />,
  "DO": <DO />,
  "DZ": <DZ />,
  "EA": <EA />,
  "EC": <EC />,
  "EE": <EE />,
  "EG": <EG />,
  "EH": <EH />,
  "ER": <ER />,
  "ESCT": <ESCT />,
  "ESGA": <ESGA />,
  "ES": <ES />,
  "ET": <ET />,
  "EU": <EU />,
  "FI": <FI />,
  "FJ": <FJ />,
  "FK": <FK />,
  "FM": <FM />,
  "FO": <FO />,
  "FR": <FR />,
  "GA": <GA />,
  "GBENG": <GBENG />,
  "GBNIR": <GBNIR />,
  "GBSCT": <GBSCT />,
  "GBWLS": <GBWLS />,
  "GB": <GB />,
  "GD": <GD />,
  "GE": <GE />,
  "GF": <GF />,
  "GG": <GG />,
  "GH": <GH />,
  "GI": <GI />,
  "GL": <GL />,
  "GM": <GM />,
  "GN": <GN />,
  "GP": <GP />,
  "GQ": <GQ />,
  "GR": <GR />,
  "GS": <GS />,
  "GT": <GT />,
  "GU": <GU />,
  "GW": <GW />,
  "GY": <GY />,
  "HK": <HK />,
  "HM": <HM />,
  "HN": <HN />,
  "HR": <HR />,
  "HT": <HT />,
  "HU": <HU />,
  "IC": <IC />,
  "ID": <ID />,
  "IE": <IE />,
  "IL": <IL />,
  "IM": <IM />,
  "IN": <IN />,
  "IO": <IO />,
  "IQ": <IQ />,
  "IR": <IR />,
  "IS": <IS />,
  "IT": <IT />,
  "JE": <JE />,
  "JM": <JM />,
  "JO": <JO />,
  "JP": <JP />,
  "KE": <KE />,
  "KG": <KG />,
  "KH": <KH />,
  "KI": <KI />,
  "KM": <KM />,
  "KN": <KN />,
  "KP": <KP />,
  "KR": <KR />,
  "KW": <KW />,
  "KY": <KY />,
  "KZ": <KZ />,
  "LA": <LA />,
  "LB": <LB />,
  "LC": <LC />,
  "LI": <LI />,
  "LK": <LK />,
  "LR": <LR />,
  "LS": <LS />,
  "LT": <LT />,
  "LU": <LU />,
  "LV": <LV />,
  "LY": <LY />,
  "MA": <MA />,
  "MC": <MC />,
  "MD": <MD />,
  "ME": <ME />,
  "MF": <MF />,
  "MG": <MG />,
  "MH": <MH />,
  "MK": <MK />,
  "ML": <ML />,
  "MM": <MM />,
  "MN": <MN />,
  "MO": <MO />,
  "MP": <MP />,
  "MQ": <MQ />,
  "MR": <MR />,
  "MS": <MS />,
  "MT": <MT />,
  "MU": <MU />,
  "MV": <MV />,
  "MW": <MW />,
  "MX": <MX />,
  "MY": <MY />,
  "MZ": <MZ />,
  "NA": <NA />,
  "NC": <NC />,
  "NE": <NE />,
  "NF": <NF />,
  "NG": <NG />,
  "NI": <NI />,
  "NL": <NL />,
  "NO": <NO />,
  "NP": <NP />,
  "NR": <NR />,
  "NU": <NU />,
  "NZ": <NZ />,
  "OM": <OM />,
  "PA": <PA />,
  "PE": <PE />,
  "PF": <PF />,
  "PG": <PG />,
  "PH": <PH />,
  "PK": <PK />,
  "PL": <PL />,
  "PM": <PM />,
  "PN": <PN />,
  "PR": <PR />,
  "PS": <PS />,
  "PT": <PT />,
  "PW": <PW />,
  "PY": <PY />,
  "QA": <QA />,
  "RE": <RE />,
  "RO": <RO />,
  "RS": <RS />,
  "RU": <RU />,
  "RW": <RW />,
  "SA": <SA />,
  "SB": <SB />,
  "SC": <SC />,
  "SD": <SD />,
  "SE": <SE />,
  "SG": <SG />,
  "SH": <SH />,
  "SI": <SI />,
  "SJ": <SJ />,
  "SK": <SK />,
  "SL": <SL />,
  "SM": <SM />,
  "SN": <SN />,
  "SO": <SO />,
  "SR": <SR />,
  "SS": <SS />,
  "ST": <ST />,
  "SV": <SV />,
  "SX": <SX />,
  "SY": <SY />,
  "SZ": <SZ />,
  "TA": <TA />,
  "TC": <TC />,
  "TD": <TD />,
  "TF": <TF />,
  "TG": <TG />,
  "TH": <TH />,
  "TJ": <TJ />,
  "TK": <TK />,
  "TL": <TL />,
  "TM": <TM />,
  "TN": <TN />,
  "TO": <TO />,
  "TR": <TR />,
  "TT": <TT />,
  "TV": <TV />,
  "TW": <TW />,
  "TZ": <TZ />,
  "UA": <UA />,
  "UG": <UG />,
  "UM": <UM />,
  "UN": <UN />,
  "US": <US />,
  "UY": <UY />,
  "UZ": <UZ />,
  "VA": <VA />,
  "VC": <VC />,
  "VE": <VE />,
  "VG": <VG />,
  "VI": <VI />,
  "VN": <VN />,
  "VU": <VU />,
  "WF": <WF />,
  "WS": <WS />,
  "XK": <XK />,
  "XX": <XX />,
  "YE": <YE />,
  "YT": <YT />,
  "ZA": <ZA />,
  "ZM": <ZM />,
  "ZW": <ZW />,
})

const CountryDialCode = {
  "TW" : "+886",
  "AF" : "+93",
  "AL" : "+355",
  "DZ" : "+213",
  "AS" : "+1-684",
  "AD" : "+376",
  "AO" : "+244",
  "AI" : "+1-264",
  "AQ" : "+672",
  "AG" : "+1-268",
  "AR" : "+54",
  "AM" : "+374",
  "AW" : "+297",
  "AU" : "+61",
  "AT" : "+43",
  "AZ" : "+994",
  "BS" : "+1-242",
  "BH" : "+973",
  "BD" : "+880",
  "BB" : "+1-246",
  "BY" : "+375",
  "BE" : "+32",
  "BZ" : "+501",
  "BJ" : "+229",
  "BM" : "+1-441",
  "BT" : "+975",
  "BO" : "+591",
  "BQ" : "+599",
  "BA" : "+387",
  "BW" : "+267",
  "BV" : "+47",
  "BR" : "+55",
  "IO" : "+246",
  "VG" : "+1-284",
  "BN" : "+673",
  "BG" : "+359",
  "BF" : "+226",
  "BI" : "+257",
  "CV" : "+238",
  "KH" : "+855",
  "CM" : "+237",
  "CA" : "+1",
  "KY" : "+1-345",
  "CF" : "+236",
  "TD" : "+235",
  "CL" : "+56",
  "CN" : "+86",
  "HK" : "+852",
  "MO" : "+853",
  "CX" : "+61",
  "CC" : "+61",
  "CO" : "+57",
  "KM" : "+269",
  "CG" : "+242",
  "CK" : "+682",
  "CR" : "+506",
  "HR" : "+385",
  "CU" : "+53",
  "CW" : "+599",
  "CY" : "+357",
  "CZ" : "+420",
  "CI" : "+225",
  "KP" : "+850",
  "CD" : "+243",
  "DK" : "+45",
  "DJ" : "+253",
  "DM" : "+1-767",
  "DO" : "+1-809,1-829,1-849",
  "EC" : "+593",
  "EG" : "+20",
  "SV" : "+503",
  "GQ" : "+240",
  "ER" : "+291",
  "EE" : "+372",
  "SZ" : "+268",
  "ET" : "+251",
  "FK" : "+500",
  "FO" : "+298",
  "FJ" : "+679",
  "FI" : "+358",
  "FR" : "+33",
  "GF" : "+594",
  "PF" : "+689",
  "TF" : "+262",
  "GA" : "+241",
  "GM" : "+220",
  "GE" : "+995",
  "DE" : "+49",
  "GH" : "+233",
  "GI" : "+350",
  "GR" : "+30",
  "GL" : "+299",
  "GD" : "+1-473",
  "GP" : "+590",
  "GU" : "+1-671",
  "GT" : "+502",
  "GG" : "+44",
  "GN" : "+224",
  "GW" : "+245",
  "GY" : "+592",
  "HT" : "+509",
  "HM" : "+672",
  "VA" : "+39-06",
  "HN" : "+504",
  "HU" : "+36",
  "IS" : "+354",
  "IN" : "+91",
  "ID" : "+62",
  "IR" : "+98",
  "IQ" : "+964",
  "IE" : "+353",
  "IM" : "+44",
  "IL" : "+972",
  "IT" : "+39",
  "JM" : "+1-876",
  "JP" : "+81",
  "JE" : "+44",
  "JO" : "+962",
  "KZ" : "+7",
  "KE" : "+254",
  "KI" : "+686",
  "KW" : "+965",
  "KG" : "+996",
  "LA" : "+856",
  "LV" : "+371",
  "LB" : "+961",
  "LS" : "+266",
  "LR" : "+231",
  "LY" : "+218",
  "LI" : "+423",
  "LT" : "+370",
  "LU" : "+352",
  "MG" : "+261",
  "MW" : "+265",
  "MY" : "+60",
  "MV" : "+960",
  "ML" : "+223",
  "MT" : "+356",
  "MH" : "+692",
  "MQ" : "+596",
  "MR" : "+222",
  "MU" : "+230",
  "YT" : "+262",
  "MX" : "+52",
  "FM" : "+691",
  "MC" : "+377",
  "MN" : "+976",
  "ME" : "+382",
  "MS" : "+1-664",
  "MA" : "+212",
  "MZ" : "+258",
  "MM" : "+95",
  "NA" : "+264",
  "NR" : "+674",
  "NP" : "+977",
  "NL" : "+31",
  "NC" : "+687",
  "NZ" : "+64",
  "NI" : "+505",
  "NE" : "+227",
  "NG" : "+234",
  "NU" : "+683",
  "NF" : "+672",
  "MP" : "+1-670",
  "NO" : "+47",
  "OM" : "+968",
  "PK" : "+92",
  "PW" : "+680",
  "PA" : "+507",
  "PG" : "+675",
  "PY" : "+595",
  "PE" : "+51",
  "PH" : "+63",
  "PN" : "+870",
  "PL" : "+48",
  "PT" : "+351",
  "PR" : "+1",
  "QA" : "+974",
  "KR" : "+82",
  "MD" : "+373",
  "RO" : "+40",
  "RU" : "+7",
  "RW" : "+250",
  "RE" : "+262",
  "BL" : "+590",
  "SH" : "+290",
  "KN" : "+1-869",
  "LC" : "+1-758",
  "MF" : "+590",
  "PM" : "+508",
  "VC" : "+1-784",
  "WS" : "+685",
  "SM" : "+378",
  "ST" : "+239",
  "SA" : "+966",
  "SN" : "+221",
  "RS" : "+381",
  "SC" : "+248",
  "SL" : "+232",
  "SG" : "+65",
  "SX" : "+1-721",
  "SK" : "+421",
  "SI" : "+386",
  "SB" : "+677",
  "SO" : "+252",
  "ZA" : "+27",
  "GS" : "+500",
  "SS" : "+211",
  "ES" : "+34",
  "LK" : "+94",
  "PS" : "+970",
  "SD" : "+249",
  "SR" : "+597",
  "SJ" : "+47",
  "SE" : "+46",
  "CH" : "+41",
  "SY" : "+963",
  "TJ" : "+992",
  "TH" : "+66",
  "MK" : "+389",
  "TL" : "+670",
  "TG" : "+228",
  "TK" : "+690",
  "TO" : "+676",
  "TT" : "+1-868",
  "TN" : "+216",
  "TR" : "+90",
  "TM" : "+993",
  "TC" : "+1-649",
  "TV" : "+688",
  "UG" : "+256",
  "UA" : "+380",
  "AE" : "+971",
  "GB" : "+44",
  "TZ" : "+255",
  "VI" : "+1-340",
  "US" : "+1",
  "UY" : "+598",
  "UZ" : "+998",
  "VU" : "+678",
  "VE" : "+58",
  "VN" : "+84",
  "WF" : "+681",
  "EH" : "+212",
  "YE" : "+967",
  "ZM" : "+260",
  "ZW" : "+263",
  "AX" : "+358",
}

export const getCountryFlagFromCountryCode = (countryCode) => {
  return CountryFlagSVG[countryCode]
}

export const getCountryDialCodeDropdown = () => {
  let dropdownContent = []
  let model = Object.keys(CountryDialCode);
  for (let index=0; index<model.length; index++) {
    let item = model[index];
    dropdownContent.push({
      id: index,
      name: CountryDialCode[item],
      iconComponent: getCountryFlagFromCountryCode(item),
      roundedSymbol: undefined,
    })
  }
  return dropdownContent;
}