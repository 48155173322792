import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import InputBox from "../../Awesome-ui/InputBox";
import { signInUser } from "../../services/onboarding-service";
import Button from "../button/button.component";
import './signin-form.component.scss';
import { updateBearerToken } from "../../store/user-slice";
import { updateToast } from "../../store/toast-slice";
import { CodeAnalogy } from "../toast/toast.component";

/**
 * SignIn Form Component for SignIn Page 
 * @returns {HTMLElement}
 */
const SignInForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('michael@dm.com');
  const [password, setPassword] = useState('Scott@DM');
  const [curActive, setCurActive] = useState(0);

  function changeActive(val) {
    setCurActive(val)
  }
  function changeEmail(val) {
    setEmail(val)
  }
  function changePassword(val) {
    setPassword(val)
  }
  const handleClick = () => {
    let requestParams = {
      email: email,
      password: password
    }
    let signInPromise = signInUser(requestParams);
    signInPromise.then(res => {
      if (res.data.model) {
        dispatch(updateToast({
          code: CodeAnalogy.SUCCESS, 
          title:"Successfully logged in.",
          message: ""
        }));
        dispatch(updateBearerToken(res.data.model));
        navigate('/');
      }
      else {
        dispatch(updateToast({
          code: CodeAnalogy.ERROR, 
          title: "Something Went Wrong!",
          message: `Error : ${res.data.msg}`
        }));
      }
    })
  }
  
  return(
    <div className="signin-form-container">
      <InputBox
        placeHolder="Email"
        complete={"off"}
        type="text"
        name="email"
        value={email}
        req={true}
        SrcIcon={null}
        changeFunc={(val) => changeEmail(val)}
        ind={1}
        onFocus={() => changeActive(1)}
        onBlur={() => changeActive(null)}
        focus={curActive}
      />
      <InputBox
        placeHolder="Password"
        complete={"off"}
        type="password"
        name="password"
        value={password}
        req={true}
        SrcIcon={null}
        changeFunc={(val) => changePassword(val)}
        ind={2}
        onFocus={() => changeActive(2)}
        onBlur={() => changeActive(null)}
        focus={curActive}
      />
      <p className="forgot-password">Forgot your password ?</p>
      <Button handleClick={handleClick}>
        SIGN IN
      </Button>
    </div>
  )
}
export default SignInForm;