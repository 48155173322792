import CompactviewTableCell from "../CompactviewTableCell/CompactviewTableCell"
import "./SendContactTableHead.scss"

/**
 * This is Used in DeskTicketsCompactview Component
 *
 * Table Header of Compact View
 *
 * @param {Function} sortContent
 *
 */

const SendContactTableHead = ({ sortContent }) => {
    return (
        <div className="compactview-table-header">
            {tablecells.map((tablecell, index) => (
                <CompactviewTableCell
                    key={index}
                    tablecell={tablecell}
                    sortContent={sortContent}
                />
            ))}
        </div>
    )
}

export default SendContactTableHead

const tablecells = [
    {
        name: "FULL NAME",
    },
    {
        name: "EMAIL",
    },
    {
        name: "COMPANY",
    },
    {
        name: "CREATED AT",
    },
    {
        name: "UNSUBSCRIBED",
    },
    {
        name: "TAGS",
    },
]
