import "./tab-navigation.component.scss";

/**
 * Tab Navigation is the navigation for Pagelets
 * @param {Number} option 
 * @param {Function} setOption
 * @param {Array} optionsList
 * @returns {HTMLElement}
 */
const TabNavigation = ({option,setOption,optionsList}) => {
  const handleClick = (id) => {
    setOption(id)
  }
  return(
    <div className="tab-nav-container">
    {
      optionsList.map((item,index)=> (
        <div key={index} className={`tab-nav-item ${option=== item.option_id ?'selected':''}`} onClick={()=>handleClick(item.option_id)}>
          <p className="tab-nav-text">
            {item.name}
          </p>
        </div>
      ))
    }
    </div>
  )
}
export default TabNavigation;