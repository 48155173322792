import axios from 'axios';
import config from '../utilities/api-config';
import { store } from '../store/store';

const cassandraInstance = axios.create({
  baseURL: config.CASSANDRA_BASE_URL
})

/**
 * Add New User Data
 * @param {Object} obj: ADD_USER_DATA_SCHEMA
 */

const ADD_USER_DATA_SCHEMA = {
  accountId: "manish3243242",
  userId: "1",
  firstName: "Manish",
  middleName:"Kumar",
  lastName: "Paneri",
  contactEmailId: "manishtestest21@gmail.com",
  contactNumber: "+919001708601",
  relatedId: "1",
  platform:"pipedrive"
}
export const addUserData = async (obj) => {
  const token = store.getState().user.token;
  const CassandraAuthToken = `Bearer ${token}`;
  return await cassandraInstance
    .post(config.CASSANDRA_ENDPOINT, {
      headers: { 
        'Authorization': CassandraAuthToken
      },
      ...ADD_USER_DATA_SCHEMA,
      // ...obj
    })
    .catch((error) => {
      console.log(error)
    })
}

/**
 * Deleting User Data
 * @param {String} id: TEST_ID
 */

// const TEST_ID = '1c5644a0-5812-11ec-af16-a683e79e7f6d';
export const deleteUserData = async (id) => {
  const token = store.getState().user.token;
  const CassandraAuthToken = `Bearer ${token}`;
  return await cassandraInstance
    .delete(config.CASSANDRA_ENDPOINT, {
      headers: { 
        'Authorization': CassandraAuthToken
      },
      params: {
        id: id
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

/**
 * Updating User Data
 * @param {Object} obj: UPDATE_USER_DATA_TEST_SCHEMA
 */

// const UPDATE_USER_DATA_TEST_SCHEMA = {
//   id: "5d4e9dd7-5674-11ec-9cb5-089798724933",
//   accountId: "123",
//   userId: "1",
//   firstName: "Manish Sir",
//   middleName:"Kumar",
//   lastName: "Paneri",
//   contactEmailId: "manishkp220@gmail.com",
//   contactNumber: "+919001708601",
//   relatedId: "1",
//   platform:"pipedrive/twilio"
// }
export const updateUserData = async (obj) => {
  const token = store.getState().user.token;
  const CassandraAuthToken = `Bearer ${token}`;
  return await cassandraInstance
    .put(config.CASSANDRA_ENDPOINT, {
      headers: { 
        'Authorization': CassandraAuthToken
      },
      ...obj
    })
    .catch((error) => {
      console.log(error)
    })
}

/**
 * Receive Data Count
 */
export const getDataCount = async () => {
  const token = store.getState().user.token;
  const CassandraAuthToken = `Bearer ${token}`;
  return await cassandraInstance
    .get(config.CASSANDRA_ENDPOINT, {
      headers: { 
        'Authorization': CassandraAuthToken
      },
      params: {
        action: 'count'
      },
    })
    .catch((error) => {
      console.log(error)
    })
}

/**
 * Receive Data throught Relational ID
 */
export const getByRelationalID = async () => {
  const token = store.getState().user.token;
  const CassandraAuthToken = `Bearer ${token}`;
  return await cassandraInstance
    .get(config.CASSANDRA_ENDPOINT, {
      headers: { 
        'Authorization': CassandraAuthToken
      },
      params: {
        related_id: 1,
        action: 'allFilters',

      }
    })
    .catch((error) => {
      console.log(error)
    })
}

/**
 * Receive Single Data from unique ID
 */
export const getDataByUniqueID = async (id) => {
  const token = store.getState().user.token;
  const CassandraAuthToken = `Bearer ${token}`;
  return await cassandraInstance
    .get(config.CASSANDRA_ENDPOINT, {
      headers: { 
        'Authorization': CassandraAuthToken
      },
      params: {
        id: id,
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

/**
 * Receive Filtered Data
 * @param {Object} obj: FILTER_PARAM_OBJECT_SCHEMA
 */
export const FILTER_PARAM_OBJECT_SCHEMA = {
  userId: 1,
  contactEmailId: '',
  accountId: '',
  page_state: '',
  firstName: '',
  lastName: '',
  platform: '',
  contactNumber: '',
};
export const getFilteredDataByRelationalID = async (obj) => {
  const token = store.getState().user.token;
  const CassandraAuthToken = `Bearer ${token}`;
  return await cassandraInstance
    .get(config.CASSANDRA_ENDPOINT, {
      headers: { 
        'Authorization': CassandraAuthToken
      },
      params: {
        related_id: 1,
        action: 'search',
        ...obj
      }
    })
    .catch((error) => {
      console.log(error)
    })
}
// DEPLOY 
// let filterParams = JSON.parse(JSON.stringify(FILTER_PARAM_OBJECT_SCHEMA));
// filterParams.contactEmailId = 'manishteste2232@gmail.com';
// const result = getFilteredDataByRelationalID(filterParams);