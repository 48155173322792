import { useSelector } from 'react-redux';
import {ReactComponent as ContactList} from '../../assets/company-logo/contact-list.svg';
import {ReactComponent as Segment} from '../../assets/company-logo/Segment.svg';
import BorderListItem from '../../components/border-list-item/border-list-item.component';
import './contact-body.scss'

/**
 * Contact Body : Segment Pagelet - Option 3 of Contact Page
 * @returns {HTMLElement}
 */
const ContactBodySegment = () => {
  const contact_count = useSelector((state) => state.cassandra.count);
  return(
    <div className="contact-body-container">
      <div className="dropdown-container">
        <div className="dropdown" />
      </div>
      <div className="search-bar-container">
        <div className="search-bar" />
      </div>
      <div className="body-header" />
      <div className="body-horizontal-list">
        <BorderListItem handleClick={() => alert("new contact action")}>
          Create new contact
        </BorderListItem>
        <BorderListItem icon={<ContactList />} handleClick={() => alert("new contact action")}>
          Enter a list of contact
        </BorderListItem>
        <BorderListItem icon={<Segment />} handleClick={() => alert("new contact action")}>
          Setup Segment Integration
        </BorderListItem>
      </div>
      <p className="body-text">
        {contact_count > 0 ? contact_count:'No'} 
        {contact_count > 1 ? ' Contacts ':' Contact '}
        Found</p>
      <p className="body-para">You do not have any contacts. You can add contacts through Awesome Send Forms or import them</p>
    </div>
  )
}
export default ContactBodySegment;