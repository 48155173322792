import { useState } from "react";
import InputBox from "../../Awesome-ui/InputBox";
import './signup-form.component.scss';

/**
 * SignUp Form Component for SignUp Page 
 * @returns {HTMLElement}
 */
const SignUpForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [curActive, setCurActive] = useState(0);

  function changeActive(val) {
    setCurActive(val)
  }

  function changeFirstName(val) {
    setFirstName(val)
  }
  function changeLastName(val) {
    setLastName(val)
  }
  function changeEmail(val) {
    setEmail(val)
  }
  function changeCompany(val) {
    setCompanyName(val)
  }
  function changePhone(val) {
    setPhoneNumber(val)
  }
  
  return(
    <div className="signup-form-container">
      <div className="name-container">
        <div className="name-item">
          <InputBox
            placeHolder="First Name"
            complete={"off"}
            type="text"
            name="firstName"
            value={firstName}
            req={true}
            SrcIcon={null}
            changeFunc={(val) => changeFirstName(val)}
            ind={5}
            onFocus={() => changeActive(0)}
            onBlur={() => changeActive(null)}
            focus={curActive}
          />
        </div>
        <div className="name-item">
          <InputBox
            placeHolder="Last Name"
            complete={"off"}
            type="text"
            name="lastName"
            value={lastName}
            req={true}
            SrcIcon={null}
            changeFunc={(val) => changeLastName(val)}
            ind={1}
            onFocus={() => changeActive(1)}
            onBlur={() => changeActive(null)}
            focus={curActive}
          />
        </div>
      </div>
      <InputBox
        placeHolder="Email"
        complete={"off"}
        type="text"
        name="email"
        value={email}
        req={true}
        SrcIcon={null}
        changeFunc={(val) => changeEmail(val)}
        ind={2}
        onFocus={() => changeActive(2)}
        onBlur={() => changeActive(null)}
        focus={curActive}
      />
      <InputBox
        placeHolder="Company"
        complete={"off"}
        type="text"
        name="company"
        value={companyName}
        req={true}
        SrcIcon={null}
        changeFunc={(val) => changeCompany(val)}
        ind={3}
        onFocus={() => changeActive(3)}
        onBlur={() => changeActive(null)}
        focus={curActive}
      />
      <InputBox
        placeHolder="Phone No."
        complete={"off"}
        type="text"
        name="phone"
        value={phoneNumber}
        req={true}
        SrcIcon={null}
        changeFunc={(val) => changePhone(val)}
        ind={4}
        onFocus={() => changeActive(4)}
        onBlur={() => changeActive(null)}
        focus={curActive}
      />
    </div>
  )
}
export default SignUpForm;