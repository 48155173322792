import axios from 'axios';
import config from '../utilities/api-config';

const RELATED_ID = 2;

// NUMBER

export const getListOfCountries = async () => {
  return await axios
    .get(`${config.TWILIO_BASE_URL}/numbers?action=countries`)
    .then(res => res)
    .catch(err => {
      console.log(err);
      console.log("An error occurred. See your browser console for more information.")
    });
}

export const getListOfNumbersOfCountry = async (data) => {
  const {country_code} = data;
  return await axios
    .get(`${config.TWILIO_BASE_URL}/numbers?action=search&countryCode=${country_code}&numberType=local&AreaCode=&Contains=`)
    .then(res => res)
    .catch(err => {
      console.log(err);
      console.log("An error occurred. See your browser console for more information.")
    });
}

export const getListOfPurchasedNumbers = async () => {
  return await axios
    .get(`${config.TWILIO_BASE_URL}/numbers?relatedId=${RELATED_ID}&action=fetch`)
    .then(res => res)
    .catch(err => {
      console.log(err);
      console.log("An error occurred. See your browser console for more information.")
    });
}
