import { ReactComponent as Polygon } from "./icons/Polygon.svg"
import "./DropDownSelectedItem.scss"

/**
 * For Create Dropdown Selected Box Use This DropDownSelectedItem Component
 *
 * This is Used in DropdownContainer Component
 *
 * @param {Object} selectedItem
 * @param {Boolean} showDropDown
 * @param {Function} setShowDropDown
 * @param {Boolean} showOnlyIconInSelect
 * @param {String} selectedItemColor
 *
 */

const DropDownSelectedItem = ({
    selectedItem,
    showDropDown,
    setShowDropDown,
    showOnlyIconInSelect,
    selectedItemColor,
}) => {
    const onClickOutsideListener = () => {
        document.removeEventListener("click", onClickOutsideListener)
        setShowDropDown(false)
    }
    if (selectedItem) {
        const { name, component, iconComponent } = selectedItem
        // Function for close dropdown when click outside
        
        return (
            <div
                className="dropdown-selected-box"
                onClick={() => setShowDropDown(!showDropDown)}
                onMouseLeave={() => {
                    document.addEventListener("click", onClickOutsideListener)
                }}
            >
                <div>
                    {iconComponent ? iconComponent : null}
                    {component && component}
                    <p style={{ color: selectedItemColor && selectedItemColor }}>
                        {!showOnlyIconInSelect && name}
                    </p>
                </div>
                <Polygon className="dropdown-arrow"/>
            </div>
        )
    }
    else {
        return (
            <div
                className="dropdown-selected-box"
                onClick={() => setShowDropDown(!showDropDown)}
                onMouseLeave={() => {
                    document.addEventListener("click", onClickOutsideListener)
                }}
            >
                <p>🚩</p>
                <p style={{ color: "red" }}>
                    ERROR !
                </p>
                <Polygon className="dropdown-arrow"/>
            </div>
        )
    }
}

export default DropDownSelectedItem
