import React from "react"
import "./DrawerElementWrapper.scss";

/**
 * use this to wrapp a component in sidedrawer and it provides title
 * and flex childern
 * @param {*} param0
 * @returns
 */
const DrawerElementWrapper = ({ title, children, style, compStyle }) => {
    return (
        <div style={compStyle}>
            <div className="aui-side-bar-sub">{title}</div>
            <div className="aui-style-options" style={style}>
                {children}
            </div>
        </div>
    )
}

export default DrawerElementWrapper
