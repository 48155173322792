import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  profile_details: null
}

export const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    updateBearerToken: (state, action) => {
      state.token = action.payload.auth_token;
    },
    updateBearerTokenBypass: (state, action) => {
      state.token = action.payload;
    },
    updateProfileDetails: (state, action) => {
      state.profile_details = action.payload;
    },
    revokeBearerToken: (state) => {
      state.token = null;
      state.profile_details = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateBearerToken,updateBearerTokenBypass,updateProfileDetails,revokeBearerToken } = UserSlice.actions

export default UserSlice.reducer