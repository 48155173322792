import React from 'react';
import './button-white.component.scss';

/**
 * ButtonWhite - White Themed short button
 * @param {Function} handleClick
 * @param {SVGElement} icon
 * @param {Boolean} blue - If we want to convert styles for Blue Themed Component
 * @param {Boolean} green - If we want to convert styles for Green Themed Component
 * @param {Boolean} iconButton - When Only Icon based button with border component
 * @param {String} children
 * @returns {HTMLElement}
 */
const ButtonWhite = React.memo(({handleClick, icon, blue, green, style, iconButton, children}) => {
  return(
    <div style={style} className={`button-white-container ${blue? 'blue':''} ${green? 'green':''} ${iconButton? "icon-button":""}`} onClick={()=>handleClick()}>
      {
        icon ? <div className="button-icon">{icon}</div> : null
      }
      <p className="button-text">{children}</p>
    </div>
  )
})
export default ButtonWhite;