import { useRef, useEffect } from 'react';
import {ReactComponent as Users} from './images/users.svg';
import {ReactComponent as LandingPage} from './images/landing-page.svg';
import {ReactComponent as Segment} from './images/customer-segmentation.svg';
import {ReactComponent as Form} from './images/form.svg';
import {ReactComponent as Campaign} from './images/campaign.svg';
import {ReactComponent as Funnel} from './images/funnel.svg';
import "./create-menu.component.scss"
import CreateMenuItem from '../create-menu-item/create-menu-item.component';

/**
 * The Create Menu when we click on (+) create icon
 * @param {Function} setShowMenu
 * @returns {HTMLElement}
 */
const CreateMenu = ({setShowMenu}) => {

  // Handle Close Menu when clicked outside div
  const useOutsideClick = (ref) => {
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [ref])
  }

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  return(
    <div className="create-menu-container" ref={wrapperRef}>
      <div className="menu-triangle"/>
      <div className="menu-content">
      {
        menuItemOptions.map((item,index)=> (
          <CreateMenuItem
            key={index}
            icon={item.icon}
            title={item.title}
            subTitle={item.subTitle}
          />
        ))
      }
      </div>
    </div>
  )
}
export default CreateMenu;

const menuItemOptions = [
  {icon: <Users />, title: "Create Contact", subTitle: "Send effective one-time emails"},
  {icon: <LandingPage />, title: "Create Landing Page", subTitle: "Send effective one-time emails"},
  {icon: <Segment />, title: "Create Segment", subTitle: "Send effective one-time emails"},
  {icon: <Form />, title: "Create Form", subTitle: "Send effective one-time emails"},
  {icon: <Campaign />, title: "Create Campaign", subTitle: "Send effective one-time emails"},
  {icon: <Funnel />, title: "Create Funnel", subTitle: "Send effective one-time emails"},
]