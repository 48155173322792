import "./InitialsProfilePicGenerator.scss";

const IntialsProfilePicGenerator = ({name}) => {
  const generateIntials = () => {
    let nameSplited = name.split(' ');
    let initials = ''
    nameSplited.forEach(item => {
      initials = initials + item[0];
    })
    return initials.toUpperCase();
  }
  let finalInitials = generateIntials();
  return(
    <div className="initials-profile-pic-container">
      <p>{finalInitials}</p>
    </div>
  )
}
export default IntialsProfilePicGenerator;