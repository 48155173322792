import React from 'react';
import { getCountryFlagFromCountryCode } from '../../../FlagSVGProvider/flag-svg-provider';
import './cardview.scss';

const CardView = ({handleOpen,purchasedNumbers,selectNumberToEdit}) => {
    return (
        <>
            <div className="card-container">
                <div className="cards card-plus flex-all"  onClick={handleOpen}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="89" viewBox="0 0 45 89">
                        <text id="_" data-name="+" transform="translate(0 72)" fill="#cdd2d6" font-size="67" font-family="SegoeUI-Semilight, Segoe UI" font-weight="300"><tspan x="0" y="0">+</tspan></text>
                    </svg>

                    Add New Number

                </div>

                {
                    purchasedNumbers.map((card, index) => {
                        return (
                            <div className="cards" key={index} onClick={() => selectNumberToEdit(card.phoneNumber)}>
                                <div className="inner-card flex-all">
                                    {getCountryFlagFromCountryCode(card.countryCode)}
                                    <div className="card-number">
                                        {card.phoneNumber}
                                    </div>

                                </div>
                                <div className="bottom-card flex-all">
                                    {card.friendlyName}
                                    <div className="live-status"></div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>



        </>
    )
}

export default CardView
