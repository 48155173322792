import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button.component";
import SignUpForm from "../../components/signup-form/signup-form.component";
import './signup.page.scss';

/**
 * SignUp Page : Typical SignUp Page
 * @returns {HTMLElement}
 */
const SignUp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Sign Up | Awesome Send"
    // This is for updating title of page
  },[]);

  const handleClick = () => {
    navigate('/');
  }

  return(
    <div className="sign-up-container">
      <p className="welcome-title">Try Awesome Social free for 21 days</p>
      <div className="box-container">
        <div className="top-logo-space" />
        <SignUpForm />
        <Button handleClick={handleClick}>
          SIGN UP FOR FREE
        </Button>
        <p className="tnc-text">By clicking on "SIGN UP FOR FREE" you agree to our <span className="highlight">Terms</span> and acknowledge having read our <span className="highlight">Privacy Policy</span></p>
      </div>
    </div>
  )
}
export default SignUp; 