import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: [],
  count: 0,
}

export const CassandraSlice = createSlice({
  name: "CassandraSlice",
  initialState,
  reducers: {
    updateContacts: (state, action) => {
      state.contacts = action.payload;
    },
    updateContactCount: (state, action) => {
      state.count = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateContacts,updateContactCount } = CassandraSlice.actions

export default CassandraSlice.reducer