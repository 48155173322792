import CompactviewTableCell from "../CompactviewTableCell/CompactviewTableCell"
import "./DeskTicketsCompactviewHead.scss"

/**
 * This is Used in DeskTicketsCompactview Component
 *
 * Table Header of Compact View
 *
 * @param {Function} sortContent
 *
 */

const DeskTicketsCompactviewHead = ({ sortContent }) => {
    return (
        <div className="compactview-table-header">
            {tablecells.map((tablecell, index) => (
                <CompactviewTableCell
                    key={index}
                    tablecell={tablecell}
                    sortContent={sortContent}
                />
            ))}
        </div>
    )
}

export default DeskTicketsCompactviewHead

const tablecells = [
    {
        name: "Name",
        sortingArrows: true,
    },
    {
        name: "Total Contacts",
        sortingArrows: true,
    },
    {
        name: "Type",
    },
    {
        name: "Last Updated",
        sortingArrows: true,
    },
    {
        name: "Creator",
    },
    {
        name: "Created",
        sortingArrows: true,
    },
]
