import { useState } from "react";
import BlankInputBox from "../../components/blank-input-box/blank-input-box.component";
import "./contact-detail-form.pagelet.scss";

/**
 * Contact Detail Form is the form appear when we open a specific contact id
 * @returns {HTMLElement}
 */
const ContactDetailForm = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fullName, setFullName] = useState('');
  const [lastActivity, setLastActivity] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [company, setCompany] = useState('');
  const [website, setWebsite] = useState('');
  const [userID, setUserID] = useState('');
  const [uniqueID, setUniqueID] = useState('');
  return(
    <div className="contact-detail-form-container">
      <form className="form-content">
      <p className="form-title">Email Address</p>
      <BlankInputBox 
        value={email}
        setValue={setEmail}
        large={true}
      />
      <p className="form-title">Name</p>
      <div className="two-entry-container">
        <div className="entry-item">
          <BlankInputBox 
            value={firstName}
            setValue={setFirstName}
            large={true}
          />
          <p className="entry-title">First</p>
        </div>
        <div className="entry-item">
          <BlankInputBox 
            value={lastName}
            setValue={setLastName}
            large={true}
          />
          <p className="entry-title">Last</p>
        </div>
      </div>
      <p className="form-title">Email Address</p>
      <BlankInputBox 
        value={email}
        setValue={setEmail}
        large={true}
      />
      <p className="form-title">Full Name</p>
      <BlankInputBox 
        value={fullName}
        setValue={setFullName}
        large={true}
      />
      <div className="two-entry-container">
        <div className="entry-item">
          <p className="form-title">Last Activity</p>
          <BlankInputBox 
            value={lastActivity}
            setValue={setLastActivity}
            large={true}
          />
        </div>
        <div className="entry-item">
          <p className="form-title">Created At</p>
          <BlankInputBox 
            value={createdAt}
            setValue={setCreatedAt}
            large={true}
          />
        </div>
      </div>
      <p className="form-title">Company</p>
      <BlankInputBox 
        value={company}
        setValue={setCompany}
        large={true}
      />
      <p className="form-title">Website</p>
      <BlankInputBox 
        value={website}
        setValue={setWebsite}
        large={true}
      />
      <p className="form-title">User ID</p>
      <BlankInputBox 
        value={userID}
        setValue={setUserID}
        large={true}
      />
      <p className="form-title">Unique ID</p>
      <BlankInputBox 
        value={uniqueID}
        setValue={setUniqueID}
        large={true}
      />
      </form>
    </div>
  )
}
export default ContactDetailForm;