import { useNavigate } from "react-router-dom";
import Tag from "../../components/tag/tag.component";
import "./SendContactTableRow.scss"

/**
 * This is Used in DeskTicketsCompactview Component
 *
 * For Table Rows
 *
 * @param {Object} ticket
 *
 */

const SendContactTableRow = ({data}) => {
    let navigate = useNavigate();
    const {id,
        firstname,
        lastname,
        contact_emailid,
        company,
    } = data;

    const openIndividualContact = () => {
        navigate(`/contacts/${id}`)
    }
    return (
        <div
            className="compactview-table-row"
            onClick={() => openIndividualContact()}
        >
            <p>{`${firstname} ${lastname}`}</p>
            <p>{contact_emailid}</p>
            <p>{company}</p>
            <p>{"--"}</p>
            <p>{"--"}</p>
            <div><Tag /></div>
        </div>
    )
}

export default SendContactTableRow;