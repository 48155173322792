import React from "react";
import "./NotificationContainer.scss";

/**
 * Notification Container Used In NotificationBars Component
 *
 * Notification Mmessages
 *
 *  @param {String} time
 *  @param {String} title
 *  @param {String} content
 *
 */

const NotificationContainer = ({ createdAt, userId, msg }) => {
  return (
    <div className="notification-container">
      <div style={{"height":"36px","width":"36px", "backgroundColor": "yellow"}} />
      <div className="notification-content">
        <h6>{userId && userId}</h6>
        <p>{msg}</p>
        <p>{createdAt && createdAt}</p>
      </div>
    </div>
  );
};

export default NotificationContainer;
