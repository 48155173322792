import OnboardingItem from "../../components/onboarding-item/onboarding-item.component";
import StepProgressBar from "../../components/step-progress-bar/step-progress-bar.component";
import "./getting-started.pagelet.scss";

/**
 * Getting Started Pagelet - is the onboarding page to complete pending tasks before jumping to dashboard
 * @returns {HTMLElement}
 */
const GettingStarted = () => {
  return(
    <div className='onboarding-content'>
      <div className='onboarding-heading'>
        <p className='onboarding-title'>Getting Started</p>
        <div className="onboarding-progress">
          <StepProgressBar list={onboardingList}/>
        </div>
      </div>
      <div className='onboarding-list'>
      {
        onboardingList.map((item,index) => (
          <OnboardingItem 
            key={index}
            title={item.title} 
            details={item.details} 
            imgSrc={item.imgSrc} 
            isDone={item.isDone} />
        ))
      }
      </div>
    </div>
  )
}
export default GettingStarted;

const onboardingList = [
  {
    title: "Connect your Shopify Store",
    details: "Integrate with Drip to pull your existing people and orders into Drip and get immediate insights on key customer segments.",
    imgSrc: "",
    isDone: true,
  },
  {
    title: "Import your Contacts",
    details: "Integrate with Drip to pull your existing people and orders into Drip and get immediate insights on key customer segments.",
    imgSrc: "",
    isDone: false,
  },
  {
    title: "Send your first Email",
    details: "Integrate with Drip to pull your existing people and orders into Drip and get immediate insights on key customer segments.",
    imgSrc: "",
    isDone: false,
  },
]