import { useState } from 'react';
import {ReactComponent as Download} from '../image/download.svg';
import {ReactComponent as Voicemail} from '../image/voicemail.svg';
import {ReactComponent as Volume} from '../image/volume.svg';
import {ReactComponent as Play} from '../image/play.svg';

import "./micro-component.style.scss";
const VoiceMailSettings = () => {
  const [changeMode, setChangeMode] = useState(false);
  const [isVoicemailAudio, setIsVoicemailAudio] = useState(false);
  return(
    <div className="micro-settings-container">
      <p className="ms-subtitle">Your voicemail greeting will be played when you miss a call.</p>
      {
        changeMode ? 
        <>
          <p className="ms-title">Choose type of Voicemail<span onClick={() => setChangeMode(false)} className='cancel-option'>Cancel</span></p>
          <div className="ms-office-hours">
            <div className={`ms-office-radio-container ${!isVoicemailAudio ? 'selected':''}`}>
              <input type="radio" checked={!isVoicemailAudio} onChange={() => setIsVoicemailAudio(!isVoicemailAudio)}/>
              <p className="ms-office-label">Text based Voicemail</p>
            </div>
            <div className={`ms-office-radio-container ${isVoicemailAudio ? 'selected':''}`}>
              <input type="radio" checked={isVoicemailAudio} onChange={() => setIsVoicemailAudio(!isVoicemailAudio)}/>
              <p className="ms-office-label">MP3 based Voicemail</p>
            </div>
          </div>
          {
            isVoicemailAudio ?
            <div className='ms-voicemail-upload'>
              <div className='ms-upload-button'>
                <p>Upload your MP3</p>
              </div>
            </div>
            :
            <div className='ms-voicemail-text'>
              <textarea />
            </div>
          }
        </>
        :
        <div className="ms-voicemail-container">
          <div className="voicemail-icon">
            <Voicemail />
          </div>
          <div className="voicemail-box">
            <div className="voicemail-header">
              <p className="voicemail-title">Voicemail</p>
              <p className="voicemail-change" onClick={() => setChangeMode(true)}>Change</p>
            </div>
            
            <div className="voicemail-seek-volume-container">
              <div className="voicemail-seek">
                <div className="play-button">
                  <Play />
                </div>
                <input type="range" min="1" max="100" className="media-seek" />
                <p className="voicemail-duration">00:07</p>
              </div>
              <div className="voicemail-volume">
                <div className="volume-icon">
                  <Volume />
                </div>
                <input type="range" min="1" max="100" className="audio-seek" />
                
              </div>
            </div>
            <div className="voicemail-download-container">
              <Download />
            </div>
          </div>
        </div>
      }
      
    </div>
  )
}
export default VoiceMailSettings;