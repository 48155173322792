import React from "react";
import './button-small.component.scss';

/**
 * ButtonSmall - small sized button as found in Contact Pages.. etc
 * @param {Function} handleClick
 * @param {Boolean} selected
 * @param {String} children
 * @returns {HTMLElement}
 */
const ButtonSmall = React.memo(({handleClick, selected, children}) => {
  return(
    <div className={`button-small-container ${selected?'selected':''}`} onClick={()=>handleClick()}>
      <p className="button-text">{children}</p>
    </div>
  )
})
export default ButtonSmall;