/**
 * API base URL and endpoints to be defined here
 */
const config = {
  ONBOARDING_BASE_URL: 'https://0umf7ix9s7.execute-api.ap-south-1.amazonaws.com/default',
  ONBOARDING_LOGIN_ENDPOINT: '/login',
  ONBOARDING_PROFILE_ENDPOINT: '/user',

  EMAILER_BASE_URL: 'https://95c8qgv3w2.execute-api.ap-south-1.amazonaws.com/dev',
  EMAILER_EMAIL_ENDPOINT: '/email',
  EMAILER_ACCOUNT_ENDPOINT: '/account',

  CASSANDRA_BASE_URL: 'https://1j63yokohe.execute-api.ap-south-1.amazonaws.com/default',
  CASSANDRA_ENDPOINT: '/cassandra-service',

  TWILIO_BASE_URL: 'https://wqenfd52k9.execute-api.ap-south-1.amazonaws.com/Test',
}
export default config;