import React from "react";
import Cloud from "../Icons/Cloud";
import "./NoNotificationContainer.scss";

/**
 * Container For When User Have No Notification
 *
 * Contained No Notification Message
 *
 */

const NoNotificationContainer = () => {
  return (
    <div className="no-notification-container">
      <div className="no-notification-img"> 
        <Cloud />
      </div>
      <p>You don't have any notifications</p>
    </div>
  );
};

export default NoNotificationContainer;
