import { useState } from "react";
import RoundSwitch from "../../../components/round-switch/round-switch.component";
import {ReactComponent as Hubspot} from './assets/hubspot.svg';
import {ReactComponent as Pipedrive} from './assets/pipedrive.svg';
import {ReactComponent as Zapier} from './assets/zapier.svg';
import {ReactComponent as Webhooks} from './assets/webhooks.svg';
import "./settings-component.scss";

const IntegrationItem = ({icon,title,subtitle}) => {
  const [selected, setSelected] = useState(false)
  return(
    <div className="integration-item">
      <div className="integration-detail">
        {icon}
        <div className="integration-body">
          <p className="integration-body-title">{title}</p>
          <p className="integration-body-subtitle">{subtitle}</p>
        </div>
      </div>

      <RoundSwitch theme={selected} setTheme={setSelected}/>
    </div>
  )
}

const IntegrationsSetting = () => {
  return(
    <div className="setting-component-container">
      <p className="setting-component-title">List of integrations available</p>
      <div className="integrations-list">
      {
        IntegrationsData.map(item => (
          <IntegrationItem key={item.title} icon={item.icon} title={item.title} subtitle={item.subtitle}/>
        ))
      }
      </div>
    </div>
  )
}
export default IntegrationsSetting;

const IntegrationsData = [
  {
    title: "Hubspot",
    subtitle: "Enable the flow of contact and activity between Hubspot and Awesome Dial.",
    icon: <Hubspot />
  },
  {
    title: "Pipedrive",
    subtitle: "Enable the flow of contact and activity between pipedrive and Awesome Dial.",
    icon: <Pipedrive />
  },
  {
    title: "Zapier",
    subtitle: "Automate your workflows by connecting your Awesome Dial account to any compatible Zapier Application.",
    icon: <Zapier />
  },
  {
    title: "Webhooks",
    subtitle: "Setup endpoints to get notified of changes in your Awesome Dial account.",
    icon: <Webhooks />
  }
]