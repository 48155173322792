import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as ContactList} from '../../assets/company-logo/contact-list.svg';
import DeskTicketsCompactviewHead from '../../Awesome-ui/DeskTicketsCompactviewHead/DeskTicketsCompactviewHead';
import TableRow from '../../Awesome-ui/DeskTicketsCompactviewTableRow/TableRow';
import BorderListItem from '../../components/border-list-item/border-list-item.component';
import './contact-body.scss'

/**
 * Contact Body : Lists Pagelet - Option 2 of Contact Page
 * @returns {HTMLElement}
 */
const ContactBodyLists = () => {
  const contact_count = useSelector((state) => state.cassandra.count);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  }
  return(
    <div className="contact-body-container">
      <div className="dropdown-container">
        <div className="dropdown" />
      </div>
      <div className="search-bar-container">
        <div className="search-bar" />
      </div>
      {
        contact_count ? 
        <>
          <div className="table-space-container">
            <DeskTicketsCompactviewHead />
            <TableRow onClick={() => handleNavigation('/contacts/lists/Contacts')}/>
          </div>
        </>
        :
        <>
          <div className="body-header" />
          <div className="body-horizontal-list">
            <BorderListItem handleClick={() => alert("new contact action")}>
              Create new contact
            </BorderListItem>
            <BorderListItem icon={<ContactList />} handleClick={() => alert("new contact action")}>
              Enter a list of contact
            </BorderListItem>
          </div>
          <p className="body-text">No Contact found in your list</p>
        </>
      }
    </div>
  )
}
export default ContactBodyLists;