import { useState } from 'react';
import NewNumberItem from '../new-number-item/new-number-item.component';
import {ReactComponent as Call} from './images/call.svg';
import {ReactComponent as Location} from './images/location.svg';
import "./new-number.component.scss";

const NewNumber = ({handleClose}) => {
  const [step,setStep] = useState(1);
  const [chosenNumber, setChosenNumber] = useState();

  return(
    <div className='new-number-container'>
      <div className="top-contact">
        <p className="text">Buy New Number</p>
        <div onClick={handleClose} className="cross">X</div>
      </div>
      <div className='new-number-content'>
      {
        newNumberList.map((item,index) => (
          <NewNumberItem 
            key={index}
            step={step}
            setStep={setStep}
            item={item}
            chosenNumber={chosenNumber} 
            setChosenNumber={setChosenNumber}
          />
        ))
      }
      </div>
      <div className='new-number-buttons'>
        <button className='buy-button' onClick={handleClose}>Buy</button>
        <button className='cancel-button' onClick={handleClose}>Cancel</button>
      </div>
    </div>
  )
}
export default NewNumber;

const newNumberList = [
  {
    step_id: 1,
    icon: <Location />,
    title: "Choose Location",
  },
  {
    step_id: 2,
    icon: <Call />,
    title: "Name your number",
  }
]