import React from "react"

import "./ButtonWithRadial.scss"

/**
 *  Button with the radial indicator in front
 *
 * @param name name inside the button
 * @param active indicate wether button is active or not
 * @param size size is button size (not in usse right now it just inherit the width)
 * @param btnStyle to give style to button
 * @param onClick button onClick function
 */
const ButtonWithRadial = ({
    name,
    active,
    size = "inherit",
    btnStyle,
    radStyle,
    onClick,
    ...props
}) => {
    return (
        <div
            onClick={onClick}
            className={`Aui-radial-btn Aui-radial-btn-${size} ${
                active && "Aui-radial-btn-active"
            }`}
            style={{ ...btnStyle }}
        >
            <div
                className={`Aui-radial ${active && "Aui-radial-active"}`}
                style={{ ...radStyle }}
            >
                <span></span>
            </div>
            <div className="Aui-btn-name">{name}</div>
        </div>
    )
}

export default ButtonWithRadial
