import "./micro-component.style.scss";
const GeneralSettings = () => {
  return(
    <div className="micro-settings-container">
      <p className="ms-title">Symbol & name</p>
      <p className="ms-subtitle">Giving a name and symbol to number will help you and your team distinguish between all the numbers.</p>
      <div className="ms-field">
        <input
          type="text"
          placeholder="🏠"
          className="ms-symbol-input"
        />
        <input 
          type="text"
          placeholder="Number Name"
          className="ms-normal-input"
        />
      </div>
      <div className="ms-flex-full">
        <div>
        <p className="ms-title">CallerID</p>
        <p className="ms-subtitle">Set the Called ID you want others to see when you make calls.</p>
        </div>
        <div className="ms-configure-button">
          <p>Configure</p>
        </div>
      </div>
    </div>
  )
}
export default GeneralSettings;