import { useState, useEffect } from "react";
import DropdownContainer from "../../DropdownContainer/DropdownContainer";
import { DropdownContentGenerator } from "../../../helper/dropdown-content.helper";
import { getListOfCountries, getListOfNumbersOfCountry } from "../../../services/twilio-api";
import TabNavigation from "../../../components/tab-navigation/tab-navigation.component";
import NameNumber from "./name-number"; 

const ChooseLocation = ({chosenNumber, setChosenNumber}) => {
  const [option, setOption] = useState(0);
  const [country, setCountry] = useState(0);
  const [state, setState] = useState(1);
  const [locality, setLocality] = useState(0);
  const [loading, setLoading] = useState(true);
  const [microLoading, setMicroLoading] = useState(true);
  const [countryDropdown, setCountryDropdown] = useState(null);
  const [localityDropdown, setLocalityDropdown] = useState(null);
  const [numbersOfCountry, setNumbersOfCountry] = useState([]);
  
  const getNameFromDropdownContent = (data, id) => {
    for (let i=0; i<data.length; i++) {
      let item = data[i];
      if (item.id === id) {
        return item.name
      }
    }
    return ''
  }

  const numberList = () => {
    let list = []
    if (country && numbersOfCountry) {
      if (numbersOfCountry.length) {
        for (let i=0; i < numbersOfCountry.length; i++) {
          let item = numbersOfCountry[i];
          if (locality!==0) {
            let localityString = getNameFromDropdownContent(localityDropdown.dropdownContent, locality);

            if (item.locality === localityString) {
              list.push(
                <NameNumber 
                  key={i}
                  number={item.friendly_name} 
                  location={`${item.locality}, ${item.iso_country}`} 
                  price={"$1.00"} 
                  active={chosenNumber ? chosenNumber.friendly_name === item.friendly_name : false}
                  onClickEvent={() => setChosenNumber(item)}
                />
              )
            }
          }
          else {
            list.push(
              <NameNumber 
                key={i}
                number={item.friendly_name} 
                location={`${item.locality}, ${item.iso_country}`} 
                price={"$1.00"} 
                active={chosenNumber ? chosenNumber.friendly_name === item.friendly_name : false}
                onClickEvent={() => setChosenNumber(item)}
              />
            )
          }
        }
      }
    }
    return list;
  }

  const collectLocalityOptions = (data) => {
    let localitySet = new Set(["All Localities"]);
    if (data && data.length) {
      for (let i=0; i < data.length; i++) {
        let item = data[i];
        localitySet.add(item.locality)
      }
    }
    let newDropdownContent = DropdownContentGenerator([...localitySet],"locality");
    let newDropDown = {
      dropdownContent: newDropdownContent
    }
    setLocalityDropdown(newDropDown);
    setMicroLoading(false);
  }

  useEffect(() => {
    let countryPromise = getListOfCountries();
    countryPromise.then(res => {
      let newDropdownContent = DropdownContentGenerator(res.data.model);
      let newDropDown = {
        dropdownContent: newDropdownContent
      }
      setCountryDropdown(newDropDown)
      setLoading(false);
    })
  },[])

  useEffect(() => {
    if (countryDropdown) {
      let data = countryDropdown.dropdownContent[country].data;
      let numbersPromise = getListOfNumbersOfCountry(data);
      numbersPromise.then(res => {
        setNumbersOfCountry(res.data.model);
        setLocality(0); //Reset to All Localities
        collectLocalityOptions(res.data.model);
      })
    }
    else {
      console.log("Skipped")
    }
  },[country])

  let renderedList = numberList();
  console.log(renderedList)
  return(
    <div className="choose-location-container">
      <div className="choose-location-nav">
        <TabNavigation option={option} setOption={setOption} optionsList={optionsList} />
        <p>{}</p>
      </div>
      <div className="choose-location-dropdown">
        {
          !loading && countryDropdown.dropdownContent ? 
          <>
            <div className="choose-location-item">
              <DropdownContainer 
                dropdown={countryDropdown}
                selectedValueId={country}
                updateSelectedValueId={setCountry}
              />
            </div>
            {
              !microLoading && localityDropdown.dropdownContent ? 
              <>
                <div className="choose-location-item">
                <DropdownContainer 
                  dropdown={stateDropDown}
                  selectedValueId={state}
                  updateSelectedValueId={setState}
                />
                </div>
                <div className="choose-location-item">
                  <DropdownContainer 
                    dropdown={localityDropdown}
                    selectedValueId={locality}
                    updateSelectedValueId={setLocality}
                  />
                </div>
              </>
              : 
              <p>Select Country</p>
            }
            
          </>
          :
          <p>Loading!!!</p>
        }
      </div>
      <div className="choose-location-list">
      {renderedList}
      </div>
    </div>
  )
}
export default ChooseLocation;

const optionsList = [
  {option_id: 0, name: "Local", icon: null},
  {option_id: 1, name: "Toll-Free", icon: null}
]


const stateDropDown = {
  dropdownContent: [
    {
      id: 1,
      name: "All States",
    },
    {
      id: 2,
      name: "Florida",
    },
    {
      id: 3,
      name: "Texas",
    },
    {
      id: 4,
      name: "Utah",
    },
  ],
}