import { Routes, Route } from 'react-router-dom';
import { ThemeContext } from '@awesomesuite-frontend/awesome-nebula';
import Dashboard from './pages/dashboard/dashboard.page';
import SignUp from './pages/signup/signup.page';
import SignIn from './pages/signin/signin.page';
import ContactMain from './pages/contact-main/contact-main.page';
import DevTools from './components/devTools/dev-tools';
import Settings from './pages/settings/settings.page';
import Page404 from './pages/404-page/404-page.page';
import Toast from './components/toast/toast.component';
import './App.scss';

function App() {
  return (
    <ThemeContext>
      <div className="App">
        <Routes>
          <Route path="/" element={<Dashboard />}>
            {/* Reason for nesting routes instead of :paramsId sort of method is to catch 404 urls */}
            <Route exact path="contacts" element={<Dashboard />} />
            
            <Route exact path="getting-started" element={<Dashboard />} />
          </Route>
          <Route exact path="settings/:settingPath" element={<Settings />} />
          <Route path="/contacts/:contactUniqueId" element={<ContactMain />} />
          <Route path="/contacts/lists/:listUniqueId" element={<ContactMain />} />
          <Route path="/contacts/newlist" element={<ContactMain />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
        
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Toast />
        <DevTools />
      </div>
    </ThemeContext>
  );
}

export default App;
