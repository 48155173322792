import {ReactComponent as Refresh} from "./image/refresh.svg";
import "./box-tab-navigation.component.scss";

/**
 * Box Tab Navigation is the navigation for Pagelets
 * @param {Number} option 
 * @param {Function} setOption
 * @param {Array} optionsList
 * @returns {HTMLElement}
 */
const BoxTabNavigation = ({option,setOption,optionsList}) => {
  const handleClick = (id) => {
    setOption(id)
  }
  return(
    <div className="box-tab-nav-container">
      <div className="box-tab-nav-refresh">
        <Refresh />
      </div>
    {
      optionsList.map((item,index)=> (
        <div key={index} className={`box-tab-nav-item ${option=== item.option_id ?'selected':''}`} onClick={()=>handleClick(item.option_id)}>
          <p>
            {item.name}
          </p>
        </div>
      ))
    }
    </div>
  )
}
export default BoxTabNavigation;