import { useState } from "react";
import "./settings-component.scss";
import "./phone-number.styles.scss";
import PhoneNumberView from "../../../Awesome-ui/PhoneNumberView/View";
import PhoneNumberHeader from "../../../components/phone-number-header/phone-number-header.component";
import ActivityItem from "../../../components/setting-activity-item/setting-activity-item.component";
import GeneralSettings from "../../../components/setting-activity-item/micro-components/general-settings";
import ManageTeamsSettings from "../../../components/setting-activity-item/micro-components/manage-team-settings";
import BusinessHoursSettings from "../../../components/setting-activity-item/micro-components/business-hours-settings";
import VoiceMailSettings from "../../../components/setting-activity-item/micro-components/voicemail-settings";
import PreferencesSettings from "../../../components/setting-activity-item/micro-components/preferences-settings";

/**
 * The Setting Sub Page : Customising Looks and UI of the Web App
 * @returns {HTMLElement}
 */
const PhoneNumberSettings = () => {
  const [phoneSettingStep, setPhoneSettingStep] = useState(0);
  const [selectedNumber, setSelectedNumber] = useState('')

  const selectNumberToEdit = (number) => {
    setSelectedNumber(number)
    setPhoneSettingStep(phoneSettingStep+1);
  }
  const goBack = () => {
    setSelectedNumber(0)
    setPhoneSettingStep(0);
  }

  const updateSettings = () => {
    console.log("Update",selectedNumber)
  }

  return(
    <div className="setting-component-container">
      <p className="setting-component-title">
        <span 
          className={`${selectedNumber ? 'visited-step':''}`} 
          onClick={() => goBack()}
        >
          Active Numbers
        </span>  
        {selectedNumber ? ` / ${selectedNumber}`:''}</p>
      <div className="phone-setting-content">
      {
        phoneSettingStep === 0 ? 
          <PhoneNumberView selectNumberToEdit={selectNumberToEdit}/>
        :
        null
      }
      {
        phoneSettingStep === 1 ? 
          <div className="phone-settings-list">
            <PhoneNumberHeader number={selectedNumber} onSave={updateSettings}/>
            <div className="phone-settings-list-content custom-scrollbar">
            {
              phoneSettingData.map(item => (
                <ActivityItem key={item.title} data={item} />
              ))
            }
            </div>
          </div>
        :
        null
      }
      </div>
    </div>
  )
}
export default PhoneNumberSettings;

const phoneSettingData = [
  {
    title: "General",
    content: <GeneralSettings />
  },
  {
    title: "Manage Teams",
    content: <ManageTeamsSettings />
  },
  {
    title: "Business Hours",
    content: <BusinessHoursSettings />
  },
  {
    title: "Voicemail",
    content: <VoiceMailSettings />
  },
  {
    title: "Preference",
    content: <PreferencesSettings />
  },
]