import { useState } from "react";
import DropdownContainer from "../../../Awesome-ui/DropdownContainer/DropdownContainer";
import {ReactComponent as Delete} from "../image/delete.svg";

import "./micro-component.style.scss";
const BusinessHoursSettings = () => {
  const [timezone, setTimezone] = useState(0);
  const [isOfficeHoursCustom, setIsOfficeHoursCustom] = useState(false)
  return(
    <div className="micro-settings-container">
      <p className="ms-title">Manage business hours</p>
      <p className="ms-subtitle">Calls after your business hours go straight to voicemail.</p>
      <p className="ms-subtitle">TIME ZONE</p>
      <div className="ms-timezone-dropdown">
        <DropdownContainer 
          dropdown={timezoneDropdown}
          selectedValueId={timezone}
          updateSelectedValueId={setTimezone}
        />
      </div>
      <p className="ms-subtitle">Office Hours</p>
      <div className="ms-office-hours">
        <div className={`ms-office-radio-container ${!isOfficeHoursCustom ? 'selected':''}`}>
          <input type="radio" checked={!isOfficeHoursCustom} onChange={() => setIsOfficeHoursCustom(!isOfficeHoursCustom)}/>
          <p className="ms-office-label">Always Open</p>
        </div>
        <div className={`ms-office-radio-container ${isOfficeHoursCustom ? 'selected':''}`}>
          <input type="radio" checked={isOfficeHoursCustom} onChange={() => setIsOfficeHoursCustom(!isOfficeHoursCustom)}/>
          <p className="ms-office-label">Custom Hours</p>
        </div>
      </div>
      {
        isOfficeHoursCustom ? 
        <div className="ms-custom-office-slots">
          <div className="ms-custom-office-delete">
            <Delete />
          </div>
          <div className="ms-weekdays">
            <div className={`ms-weekdays-item ${true ? 'selected':''}`}>
              <p>MON</p>
            </div>
            <div className={`ms-weekdays-item ${true ? 'selected':''}`}>
              <p>TUE</p>
            </div>
            <div className={`ms-weekdays-item ${true ? 'selected':''}`}>
              <p>WED</p>
            </div>
            <div className={`ms-weekdays-item ${false ? 'selected':''}`}>
              <p>THU</p>
            </div>
            <div className={`ms-weekdays-item ${true ? 'selected':''}`}>
              <p>FRI</p>
            </div>
            <div className={`ms-weekdays-item ${true ? 'selected':''}`}>
              <p>SAT</p>
            </div>
            <div className={`ms-weekdays-item ${true ? 'selected':''}`}>
              <p>SUN</p>
            </div>
          </div>
          {
            true ?
            <div className="ms-office-timeslot-item">
              <div className="ms-office-timeslot-time-input">
                <p className="ms-office-timeslot-input-label">START TIME:</p>
                <input type="time" className="ms-office-timeslot-input-element" />
              </div>
              <div className="ms-office-timeslot-time-input">
                <p className="ms-office-timeslot-input-label">END TIME:</p>
                <input type="time" className="ms-office-timeslot-input-element" />
              </div>

            </div>
            : null
          }
          <div className="ms-office-timeslot-addmore">
            <p>+ Add time slot</p>
          </div>
        </div>
        : null
      }
    </div>
  )
}
export default BusinessHoursSettings;

const timezoneDropdown = {
  dropdownContent: [
    {"id": 0, "name":"(GMT-12:00) International Date Line West","value":"Etc/GMT+12"},
    {"id": 1, "name":"(GMT-11:00) Midway Island, Samoa","value":"Pacific/Midway"},
    {"id": 2, "name":"(GMT-10:00) Hawaii","value":"Pacific/Honolulu"},
    {"id": 3, "name":"(GMT-09:00) Alaska","value":"US/Alaska"},
    {"id": 4, "name":"(GMT-08:00) Pacific Time (US & Canada)","value":"America/Los_Angeles"},
    {"id": 5, "name":"(GMT-08:00) Tijuana, Baja California","value":"America/Tijuana"},
    {"id": 6, "name":"(GMT-07:00) Arizona","value":"US/Arizona"},
    {"id": 7, "name":"(GMT-07:00) Chihuahua, La Paz, Mazatlan","value":"America/Chihuahua"},
    {"id": 8, "name":"(GMT-07:00) Mountain Time (US & Canada)","value":"US/Mountain"},
    {"id": 9, "name":"(GMT-06:00) Central America","value":"America/Managua"},
    {"id": 10, "name":"(GMT-06:00) Central Time (US & Canada)","value":"US/Central"},
    {"id": 11, "name":"(GMT-06:00) Guadalajara, Mexico City, Monterrey","value":"America/Mexico_City"},
    {"id": 12, "name":"(GMT-06:00) Saskatchewan","value":"Canada/Saskatchewan"},
    {"id": 13, "name":"(GMT-05:00) Bogota, Lima, Quito, Rio Branco","value":"America/Bogota"},
    {"id": 14, "name":"(GMT-05:00) Eastern Time (US & Canada)","value":"US/Eastern"},
    {"id": 15, "name":"(GMT-05:00) Indiana (East)","value":"US/East-Indiana"},
    {"id": 16, "name":"(GMT-04:00) Atlantic Time (Canada)","value":"Canada/Atlantic"},
    {"id": 17, "name":"(GMT-04:00) Caracas, La Paz","value":"America/Caracas"},
    {"id": 18, "name":"(GMT-04:00) Manaus","value":"America/Manaus"},
    {"id": 19, "name":"(GMT-04:00) Santiago","value":"America/Santiago"},
    {"id": 20, "name":"(GMT-03:30) Newfoundland","value":"Canada/Newfoundland"},
    {"id": 21, "name":"(GMT-03:00) Brasilia","value":"America/Sao_Paulo"},
    {"id": 22, "name":"(GMT-03:00) Buenos Aires, Georgetown","value":"America/Argentina/Buenos_Aires"},
    {"id": 23, "name":"(GMT-03:00) Greenland","value":"America/Godthab"},
    {"id": 24, "name":"(GMT-03:00) Montevideo","value":"America/Montevideo"},
    {"id": 25, "name":"(GMT-02:00) Mid-Atlantic","value":"America/Noronha"},
    {"id": 26, "name":"(GMT-01:00) Cape Verde Is.","value":"Atlantic/Cape_Verde"},
    {"id": 27, "name":"(GMT-01:00) Azores","value":"Atlantic/Azores"},
    {"id": 28, "name":"(GMT+00:00) Casablanca, Monrovia, Reykjavik","value":"Africa/Casablanca"},
    {"id": 29, "name":"(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London","value":"Etc/Greenwich"},
    {"id": 30, "name":"(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","value":"Europe/Amsterdam"},
    {"id": 31, "name":"(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","value":"Europe/Belgrade"},
    {"id": 32, "name":"(GMT+01:00) Brussels, Copenhagen, Madrid, Paris","value":"Europe/Brussels"},
    {"id": 33, "name":"(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb","value":"Europe/Sarajevo"},
    {"id": 34, "name":"(GMT+01:00) West Central Africa","value":"Africa/Lagos"},
    {"id": 35, "name":"(GMT+02:00) Amman","value":"Asia/Amman"},
    {"id": 36, "name":"(GMT+02:00) Athens, Bucharest, Istanbul","value":"Europe/Athens"},
    {"id": 37, "name":"(GMT+02:00) Beirut","value":"Asia/Beirut"},
    {"id": 38, "name":"(GMT+02:00) Cairo","value":"Africa/Cairo"},
    {"id": 39, "name":"(GMT+02:00) Harare, Pretoria","value":"Africa/Harare"},
    {"id": 40, "name":"(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius","value":"Europe/Helsinki"},
    {"id": 41, "name":"(GMT+02:00) Jerusalem","value":"Asia/Jerusalem"},
    {"id": 42, "name":"(GMT+02:00) Minsk","value":"Europe/Minsk"},
    {"id": 43, "name":"(GMT+02:00) Windhoek","value":"Africa/Windhoek"},
    {"id": 44, "name":"(GMT+03:00) Kuwait, Riyadh, Baghdad","value":"Asia/Kuwait"},
    {"id": 45, "name":"(GMT+03:00) Moscow, St. Petersburg, Volgograd","value":"Europe/Moscow"},
    {"id": 46, "name":"(GMT+03:00) Nairobi","value":"Africa/Nairobi"},
    {"id": 47, "name":"(GMT+03:00) Tbilisi","value":"Asia/Tbilisi"},
    {"id": 48, "name":"(GMT+03:30) Tehran","value":"Asia/Tehran"},
    {"id": 49, "name":"(GMT+04:00) Abu Dhabi, Muscat","value":"Asia/Muscat"},
    {"id": 50, "name":"(GMT+04:00) Baku","value":"Asia/Baku"},
    {"id": 51, "name":"(GMT+04:00) Yerevan","value":"Asia/Yerevan"},
    {"id": 52, "name":"(GMT+04:30) Kabul","value":"Asia/Kabul"},
    {"id": 53, "name":"(GMT+05:00) Yekaterinburg","value":"Asia/Yekaterinburg"},
    {"id": 54, "name":"(GMT+05:00) Islamabad, Karachi, Tashkent","value":"Asia/Karachi"},
    {"id": 55, "name":"(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi","value":"Asia/Calcutta"},
    {"id": 56, "name":"(GMT+05:30) Sri Jayawardenapura","value":"Asia/Calcutta"},
    {"id": 57, "name":"(GMT+05:45) Kathmandu","value":"Asia/Katmandu"},
    {"id": 58, "name":"(GMT+06:00) Almaty, Novosibirsk","value":"Asia/Almaty"},
    {"id": 59, "name":"(GMT+06:00) Astana, Dhaka","value":"Asia/Dhaka"},
    {"id": 60, "name":"(GMT+06:30) Yangon (Rangoon)","value":"Asia/Rangoon"},
    {"id": 61, "name":"(GMT+07:00) Bangkok, Hanoi, Jakarta","value":"Asia/Bangkok"},
    {"id": 62, "name":"(GMT+07:00) Krasnoyarsk","value":"Asia/Krasnoyarsk"},
    {"id": 63, "name":"(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi","value":"Asia/Hong_Kong"},
    {"id": 64, "name":"(GMT+08:00) Kuala Lumpur, Singapore","value":"Asia/Kuala_Lumpur"},
    {"id": 65, "name":"(GMT+08:00) Irkutsk, Ulaan Bataar","value":"Asia/Irkutsk"},
    {"id": 66, "name":"(GMT+08:00) Perth","value":"Australia/Perth"},
    {"id": 67, "name":"(GMT+08:00) Taipei","value":"Asia/Taipei"},
    {"id": 68, "name":"(GMT+09:00) Osaka, Sapporo, Tokyo","value":"Asia/Tokyo"},
    {"id": 69, "name":"(GMT+09:00) Seoul","value":"Asia/Seoul"},
    {"id": 70, "name":"(GMT+09:00) Yakutsk","value":"Asia/Yakutsk"},
    {"id": 71, "name":"(GMT+09:30) Adelaide","value":"Australia/Adelaide"},
    {"id": 72, "name":"(GMT+09:30) Darwin","value":"Australia/Darwin"},
    {"id": 73, "name":"(GMT+10:00) Brisbane","value":"Australia/Brisbane"},
    {"id": 74, "name":"(GMT+10:00) Canberra, Melbourne, Sydney","value":"Australia/Canberra"},
    {"id": 75, "name":"(GMT+10:00) Hobart","value":"Australia/Hobart"},
    {"id": 76, "name":"(GMT+10:00) Guam, Port Moresby","value":"Pacific/Guam"},
    {"id": 77, "name":"(GMT+10:00) Vladivostok","value":"Asia/Vladivostok"},
    {"id": 78, "name":"(GMT+11:00) Magadan, Solomon Is., New Caledonia","value":"Asia/Magadan"},
    {"id": 79, "name":"(GMT+12:00) Auckland, Wellington","value":"Pacific/Auckland"},
    {"id": 80, "name":"(GMT+12:00) Fiji, Kamchatka, Marshall Is.","value":"Pacific/Fiji"},
    {"id": 81, "name":"(GMT+13:00) Nuku'alofa","value":"Pacific/Tongatapu"}
  ]
}