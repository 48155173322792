import "./step-progress-bar.component.scss";

/**
 * This component generates a map of steps completed in green and steps not completed in grey
 * @param {Array} list 
 * @returns {HTMLElement}
 */
const StepProgressBar = ({list}) => {
  let steps = list.length;
  return(
    <div className="step-progress-bar-container">
    {
      list.map((item,index) => (
        <div key={index} style={{"width": `${100/steps}%`}} className={`step-bar ${item.isDone ? 'success': ''}`} />
      ))
    }
    </div>
  )
}
export default StepProgressBar;