import { getCountryFlagFromCountryCode } from "../Awesome-ui/FlagSVGProvider/flag-svg-provider";

const ROUND_SYMBOL_PROVIDER = {

}

export const generateCountryFlag = (countryCode = "US") => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export const DropdownContentGenerator = (model,mode) => {
  if (model) {
    let dropdownContent = [];
    if (mode !== "locality") {
      for (let index=0; index<model.length; index++) {
        let item = model[index];
        dropdownContent.push({
          id: index,
          name: item.country,
          iconComponent: getCountryFlagFromCountryCode(item.country_code),
          roundedSymbol: ROUND_SYMBOL_PROVIDER[item],
          data: item
        })
      }
    }
    else {
      for (let index=0; index<model.length; index++) {
        let item = model[index];
        dropdownContent.push({
          id: index,
          name: item,
          iconComponent: undefined,
          roundedSymbol: undefined,
        })
      }
    }
    return dropdownContent
  }  
}