import React from 'react';
import './contact-activity.component.scss';

/**
 * Contact Activity is the recent activity tracker that shows history of user engagement
 * @param {Array} data
 * @returns {HTMLElement}
 */
const ContactActivity = React.memo(() => {
  const activity_log = [
    {
      type: "normal",
      message: "Has been added to list List Name by xls upload",
      date: "2 days ago"
    },
    {
      type: "normal",
      message: "Has been added to list List Name by xls upload",
      date: "1 days ago"
    },
    {
      type: "success",
      message: "🎉 Has subscribed to mailing list",
      date: null
    }
  ];
  return(
    <div className="contact-activity-container">
      <div className='activity-header-container'>
        <p className='activity-title'>Recent Activity</p>
      </div>
      <p className='activity-subtitle'>Here is an activity log of all the changes and updates associated with this document</p>
      <div className='activity-list'>
      {
        activity_log.map((item,index) => (
          <div key={index} className={`activity-item ${item.type}`}>
            <div className='item-icon' />
            <p className='item-text'>
              {item.message}
              <br/><span className='item-date'>{item.date}</span>
            </p>
          </div>
        ))
      }
      </div>
    </div>
  )
})
export default ContactActivity;