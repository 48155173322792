import { useEffect } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { updateBearerTokenBypass } from "../../store/user-slice";
import ButtonWhite from '../../components/button-white/button-white.component';
import SignInForm from '../../components/signin-form/signin-form.component';
import {ReactComponent as Google} from '../../assets/company-logo/Google.svg';
import './signin.page.scss';

/**
 * SignIn Page : Typical SignIn Page
 * @returns {HTMLElement}
 */
const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Sign In | Awesome Send"
    // This is for updating title of page
    if (location.search) {
      let token = location.search.slice(3);
      dispatch(updateBearerTokenBypass(token));
      navigate('/');
    }
  },[]);

  const handleGoogleSignIn = () => {
    navigate('/');
  }

  const handleSignUp = () => {
    navigate('/signup');
  }

  return(
    <div className="sign-in-container">
      <div className="box-container">
        <div className="top-logo-space"/>
        <div className="box-content">
          <div className="mid-logo-space" />
          <p className="main-title">Welcome Back</p>
          <p className="main-subtitle">Good to see you again!</p>
          <SignInForm />
          <div className="divider">
            <div className="divider-line"/>
            <p className="divider-text">OR</p>
            <div className="divider-line"/>
          </div>
          <ButtonWhite icon={(<Google />)} handleClick={handleGoogleSignIn}>
            Sign in with Google
          </ButtonWhite>
          <p className="sign-up-text">Don't have an account? <span className="highlight" onClick={() => handleSignUp()}>Sign up Now</span></p>
        </div>
      </div>
    </div>
  )
}
export default SignIn;