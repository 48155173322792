import React from 'react';
import './listview.css';
import { ReactComponent as Settings } from './images/settings.svg';
import { getCountryFlagFromCountryCode } from '../../../FlagSVGProvider/flag-svg-provider';


const ListView = ({handleOpen, purchasedNumbers, selectNumberToEdit}) => {
    return (
        <>
            <div className="list-container">

                {
                    purchasedNumbers.map((list, index) => {
                        return (
                            <div className="list" key={index} onClick={() => selectNumberToEdit(list.phoneNumber)}>
                                <div className="flag">
                                    {getCountryFlagFromCountryCode(list.countryCode)}
                                </div>
                                <div className="lv-number flex-all">
                                    {list.phoneNumber}
                                </div>
                                <div className='lv-hours'><span>Business Hours :</span>{list.businessHours}</div>
                                <div className="lv-name flex-all">{list.friendlyName}</div>
                                <div className="lv-profile"></div>
                                <div className="lv-live flex-all">{list.isEnable ? 'Live':'Inactive'}</div>
                                <div className="lv-settings"><Settings /></div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="lv-adddiv" onClick={handleOpen}>
                <div className="lv-plus flex-all">+</div>
                Add new number
            </div>
        </>
    )
}

export default ListView
