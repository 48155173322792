import "./outlined-icon-button.component.scss";

/**
 * Outlined Icon Button are customizable button with Outlined
 * design and icon with them
 * @param {String} color
 * @param {Function} handleClick
 * @param {SVGElement} icon
 * @param {String} children
 * @returns {HTMLElement}
 */
const OutlinedIconButton = ({handleClick, color, icon, children}) => {
  return(
    <div 
      className="outlined-icon-button-container" 
      onClick={()=>handleClick()}
      style={{
        "fill": `${color}`,
        "borderColor": `${color}`,
        "color": `${color}`
      }}
    >
      {icon}
      <p className="outlined-icon-button-text">{children}</p>
    </div>
   )
}
export default OutlinedIconButton;