import React from "react"
import "./UserSideBar.scss"
import ButtonWithRadial from "../ButtonWithRadial"

import { ReactComponent as GiftIcon } from "./images/giftbox.svg"
import { ReactComponent as AccountIcon } from "./images/account.svg"
import { ReactComponent as LogoutIcon } from "./images/logout.svg"
import DrawerElementWrapper from "../DrawerElementWrapper"
import { Avatar } from "@awesomesuite-frontend/awesome-nebula"

const sideBarOptions = [
    {
        name: "SideNav Lite",
        value: "SIDE",
    },
    {
        name: "SideNav",
        value: "NAMED",
    },
    {
        name: "Top Nav",
        value: "TOP",
    },
]

const themeOptions = [
    {
        name: "Sign Theme",
        value: "DARK",
    },
    {
        name: "White Theme",
        value: "LIGHT",
    },
]

/**
 * user sidebar for the sign
 *
 * @param {*} param0
 * @returns
 */
const UserSideBar = ({
    showUserDropDown,
    setShowUserDropDown,
    userDetails,
    signOut,
    sideBarType,
    setSideBarType,
    sideBarColor,
    setSideBarColor,
}) => {
    if (!showUserDropDown) return null

    return (
        <div className="user-sidebar-container" >
            <div className="user-siderbar-dropdown">
                <div onClick={() => setShowUserDropDown(false)} className="user-sideBar-cross"><p>+</p></div>
                <div className="drawer-top-bar">
                    <Avatar 
                        status="active" 
                        imageSource="https://pbs.twimg.com/profile_images/1503591435324563456/foUrqiEw_400x400.jpg" 
                    />
                    <div className="user-info">
                        <div className="usr-name">
                            {userDetails &&
                                `${userDetails.first_name} ${userDetails.last_name}`}
                        </div>
                        <div className="usr-email">
                            {userDetails && userDetails.email_id}
                        </div>
                        <div className="setting-button">Edit Profile</div>
                    </div>
                </div>
                <div className="usr-sidebar-body">
                    <div className="usr-side-bar-title">Personalize</div>

                    <DrawerElementWrapper title="Navigation Style">
                        {sideBarOptions.map((item,index) => (
                            <ButtonWithRadial
                                key={index}
                                name={item.name}
                                active={item.value === sideBarType}
                                onClick={() => {setSideBarType(item.value)}}
                            />
                        ))}
                    </DrawerElementWrapper>
                    <DrawerElementWrapper title="Theme Style">
                        {themeOptions.map((item,index) => (
                            <ButtonWithRadial
                                key={index}
                                name={item.name}
                                active={item.value === sideBarColor}
                                onClick={() => setSideBarColor(item.value)}
                            />
                        ))}
                    </DrawerElementWrapper>
                    <div className="appication-note">
                        Application Level Note can be made for each app. So Just make a
                        component
                    </div>
                    <div className="application-offer">
                        <div>
                            <b>Get upto 2 months free</b>
                            <p>
                                Share your experience with Awesome Social and Upto 2
                                Months of subscription free.
                            </p>
                        </div>
                        <GiftIcon />
                    </div>
                    <div className="usr-bottom-nav">
                        <div
                            className="usr-button"
                            onClick={() =>
                                (window.location = "https://obliqaccount.netlify.app/")
                            }
                        >
                            <AccountIcon />
                            <span>My Account</span>
                        </div>
                        <div className="usr-button" onClick={() => signOut()}>
                            <LogoutIcon />
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserSideBar
