import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlankInputBox from "../../components/blank-input-box/blank-input-box.component";
import ButtonWhite from "../../components/button-white/button-white.component";
import ExcelSheet from "../../components/excel-sheet/excel-sheet.component";
import {ReactComponent as Close} from './image/Close.svg';
import "./new-contact-list.pagelet.scss";

const NewContactList = ({existingListId}) => {
  const [listTitle, setListTitle] = useState('Contact List #1');

  const navigate = useNavigate();
  const contacts = useSelector((state) => state.cassandra.contacts);
  let contactData = []

  if (existingListId) {
    contactData = contacts[existingListId]
  }
  const handleCreateList = () => {
    alert("Handle Create List");
  }
  const handleCloseList = () => {
    navigate('/contacts')
  }
  return(
    <div className="new-contact-list-container">
      <div className="list-header">
        <div className="header-section">
          <p className="section-title">Name your list</p>
          <BlankInputBox 
            value={`${existingListId ? listTitle:''}`}
            setValue={setListTitle}
          />
        </div>
        <div className="header-section large">
          <p className="section-title">Choose the subscription status of these contacts.</p>
          <div className="status-container">
            <div className="status-item" />
            <div className="status-item" />
          </div>
        </div>
        <div className="permission-container">
          <div className="permission-icon" />
          <p className="permission-text" >Your subscribers gave you persission to email them.</p>
        </div>
        <div className="list-button-container">
        {
          existingListId ? 
          <ButtonWhite green={true} handleClick={handleCreateList}>
            Update List
          </ButtonWhite>
          :
          <ButtonWhite green={true} handleClick={handleCreateList}>
            Create List
          </ButtonWhite>
        }
          <ButtonWhite 
            icon={<Close />} 
            iconButton={true}
            handleClick={handleCloseList}
          />
        </div>
      </div>
      <div className="list-content">
        <ExcelSheet data={contactData}/>
      </div>
    </div>
  )
}
export default NewContactList;