import { useState,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { revokeToast } from '../../store/toast-slice';
import { Alert } from '@awesomesuite-frontend/awesome-nebula';
import "./toast.component.scss";

export const CodeAnalogy = Object.freeze({
  WARN: 'warning',
  PROGRESS: 'progress',
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
})
const Toast = () => {
  const dispatch = useDispatch();
  const [fadeOut, setFadeOut] = useState(true);
  const code = useSelector((state) => state.toast.code);
  const title = useSelector((state) => state.toast.title);
  const message = useSelector((state) => state.toast.message);
  useEffect(() => {
    if (title) {
      setFadeOut(false);
      setTimeout(() => {
        setFadeOut(true);
        dispatch(revokeToast());
      },3500)
    }
  },[title])

  const forceClose = () => {
    setFadeOut(true);
    dispatch(revokeToast());
  }

  return(
    <div className={`toast-message ${code} ${fadeOut?'fade-out':''}`}>
      <Alert 
        category={code}
        title={title} 
        message={message} 
        onClose={() => forceClose()}
      />
    </div>
  )
}
export default Toast;