import "./round-switch.component.scss";

/**
 * Round Switch is on off switch used in settings (customize)
 * @param {Boolean} theme
 * @param {Function} setTheme 
 * @returns {HTMLElement}
 */
const RoundSwitch = ({theme,setTheme}) => {
  const handleSwitch = () => {
    setTheme(!theme)
  }
  return(
    <label className="switch">
      <input type="checkbox" checked={theme} onClick={()=>handleSwitch()}/>
      <span className="slider round"></span>
    </label>
  )
}
export default RoundSwitch;