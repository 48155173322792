import React from 'react';
import './create-menu-item.component.scss';

/**
 * Create Menu Item for Create Menu : the dropdown of (+) create button
 * @param {String} title 
 * @param {String} subTitle
 * @param {SVGElement} icon
 * @returns {HTMLElement}
 */
const CreateMenuItem = React.memo(({title,icon,subTitle}) => {
  return(
    <div className="menu-item">
      <div className="menu-item-header">
        <div className="menu-icon">
          {icon}
        </div>
        <p className="menu-text">{title}</p>
      </div>
      <div className="menu-para">{subTitle}</div>
    </div>
  )
})
export default CreateMenuItem;