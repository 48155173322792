import { useState } from "react";
import DropdownContainer from "../../Awesome-ui/DropdownContainer/DropdownContainer";
import "./excel-sheet-col.component.scss";

/**
 * Individual Column of Excel Sheet
 * @param {Number} rowNumber 
 * @returns 
 */
const ExcelSheetCol = ({rowNumber,data}) => {
  const [attributeId, setAttributeId] = useState(0);

  const handleAttributeChange = (value) => {
    setAttributeId(value)
  }

  // Function Deals with onEnter event to move toward next cells
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (index < rowNumber-1) {          // To check null value error : when exeeded beyond index
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    }
  };

  // This Function deals with actions when we copy multiple cell content from spreadsheet like
  // Google Sheet or Excel, so it should paste them in individual cells 
  // NOTE: This is only applicable for single column data copy only
  //       for future dev: you can make similar logic for detection of /t 
  const handlePaste = (event) => {
    let originalValue = event.target.value;
    if (originalValue.includes('\n')) {
      let feedThis = ''
      let form = event.target.form;
      let index = [...form].indexOf(event.target);
      while (originalValue.includes('\n')) {
        let newLineIndex = originalValue.indexOf('\n');
        feedThis = originalValue.slice(0,newLineIndex);
        originalValue = originalValue.slice(newLineIndex+1);

        form.elements[index].value = feedThis;
        index=index+1;
      }
      form.elements[index].value = originalValue;
    }
  }

  const generateRows = () => {
    let rowList = [];
    let selectedAttributeValue = attributeDropdown.dropdownContent[attributeId].value;
    for (let row=0; row < rowNumber; row++) {
      rowList.push(
        <div key={row} className="row-cell">
          <textarea 
            value={data.length > 0 ? data[row][selectedAttributeValue] : undefined}
            onKeyDown={handleEnter} 
            onChange={handlePaste} 
            className="row-input" 
            placeholder=""/>
        </div>
      )
    }
    return rowList
  }
  let rowList = generateRows();
  return(
    <div className="excel-sheet-col-container">
      <div className="col-header">
        <DropdownContainer
          dropdown={attributeDropdown}
          selectedValueId={attributeId}
          updateSelectedValueId={handleAttributeChange}
        />
      </div>
      <form className="col-content">
      {
        rowList
      }
      </form>
    </div>
  )
}
export default ExcelSheetCol;

const attributeDropdown = {
  dropdownContent: [
    {
      id: 0,
      name: "Email",
      value: "contact_emailid"
    },
    {
      id: 1,
      name: "Company",
      value: "company"
    },
    {
      id: 2,
      name: "User ID",
      value: "account_id"
    },
    {
      id: 3,
      name: "Name",
      value: "firstname"
    },
  ],
}