import React from 'react'
import './border-list-item.component.scss';

/**
 * Bordered List Item : Found in Contact Page - Square Shaped Blocks
 * @param {SVGElement} icon
 * @param {Function} handleClick
 * @param {String} children
 * @returns {HTMLElement}
 */
const BorderListItem = React.memo(({icon, handleClick, children}) => {
  return (
    <div className="body-item">
      <div className="body-item-icon" onClick={()=>handleClick()}>
        {icon}
      </div>
      <p className="body-item-text">{children}</p>
    </div>
  )
})
export default BorderListItem;