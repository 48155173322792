import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonSmall from "../button-small/button-small.component";
import OutlinedIconButton from "../outlined-icon-button/outlined-icon-button.component";
import {ReactComponent as Unsub} from "./images/unsub.svg";
import {ReactComponent as Delete} from "./images/delete.svg";
import {ReactComponent as UserMinus} from "./images/user-minus.svg";
import "./contact-info.component.scss";

/**
 * Contact Info : Left sided Detail Page in /contacts/<id> route
 * @param {Object} data
 * @returns {HTMLElement}
 */
const ContactInfo = React.memo(({ data }) => {
  const {id,
    firstname,
    lastname,
    contact_emailid,
  } = data;
  let navigate = useNavigate();
  const handleContactClick = () => {
    navigate("/contacts")
  }
  const handleButtonClick = () => {
    alert("Clicked on Contact Info Component");
  }
  return(
    <div className="contact-info-container">
      <ButtonSmall handleClick={handleContactClick}>
        Contacts
      </ButtonSmall>
      <div className="info-container">
        <div className="info-photo" />
        <p className="info-name">{`${firstname} ${lastname}`}</p>
        <p className="info-text">{contact_emailid}</p>
        <div className="info-status">
          <p>{"--"}</p>
        </div>
      </div>
      <div className="info-divider">
        <p className="info-section-title">Unique ID</p>
        <p className="info-text">{id}</p>
      </div>
      <div className="info-divider">
        <p className="info-section-title">Tags</p>
        
      </div>
      <div className="info-divider-line" /> 
      <div className="info-divider bottom">
        <div className="info-button-container">
          <OutlinedIconButton 
            handleClick={handleButtonClick}
            color="#F3666C"
            icon={<Delete />}
          >
            Delete Contact
          </OutlinedIconButton>
          <OutlinedIconButton 
            handleClick={handleButtonClick}
            color="#FF9C6D"
            icon={<Unsub />}
          >
            Unsubscribe
          </OutlinedIconButton>
          <OutlinedIconButton 
            handleClick={handleButtonClick}
            color="#8894DB"
            icon={<UserMinus />}
          >
            Block
          </OutlinedIconButton>
        </div>
      </div> 
    </div>
  )
})
export default ContactInfo;