import ButtonSmall from '../button-small/button-small.component';
import {ReactComponent as Check} from './images/check.svg';
import "./onboarding-item.component.scss";

/**
 * Onboarding Item is the individual card of Getting Started Page
 * @param {String} title 
 * @param {String} details
 * @param {Source} imgSrc
 * @param {Boolean} isDone
 * @return {HTMLElement}
 */
const OnboardingItem = ({title,details,imgSrc,isDone}) => {

  const handleGetStartedClick = () => {
    alert(`${title} : Action`);
  }
  return(
    <div className="onboarding-item-container">
      <div className="onboard-image" />
      <div className="onboard-detail-container">
        <p className="onboard-title">{title}</p>
        <p className="onboard-subtitle">{details}</p>
        <ButtonSmall handleClick={handleGetStartedClick}>
          Get Started
        </ButtonSmall>
        <div className="onboard-status">
          {isDone ? <Check /> : null}
        </div>
      </div>
    </div>
  )
}
export default OnboardingItem;