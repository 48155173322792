import { useState,useEffect,Fragment } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import Sidebar from "../../components/sidebar/sidebar.component";
import Navbar from "../../components/navbar/navbar.component";
import HelpSideNavbar from "../../Awesome-ui/HelpSideNavbar/HelpSideNavbar";
import NotificationBar from "../../Awesome-ui/NotificationBar/NotificationBar";
import {SettingsData,SettingsMicroPages} from "./settings-data";
import { updateToast } from "../../store/toast-slice";
import { CodeAnalogy } from "../../components/toast/toast.component";
import '../dashboard/dashboard.page.scss';
import "./settings.page.scss"

/**
 * Settings Page 
 * @returns {HTMLElement}
 */
const Settings = () => {
  let urlParam = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);
  const nav_bar_mode = useSelector((state) => state.ux.nav_bar_mode);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [selectedSettingTitle, setSelectedSettingTitle] = useState("");

  useEffect(() => {
    document.title = "Settings | Awesome Send"
    // This is for updating title of page
  },[])

  useEffect(() => {
    if (!token) {
      navigate('/signin');
      dispatch(updateToast({
        code: CodeAnalogy.WARN, 
        title: "Please login to proceed!",
        message: ""
      }));
    }    
  },[]);

  const handleSettingClick = (title,path) => {
    setSelectedSettingTitle(title);
    navigate(`/settings/${path}`);
  }

  console.log(urlParam);

  return(
    <div className="dashboard-containter">
      {nav_bar_mode!=="TOP" ? <Sidebar /> : null}
      <div className="dashboard-content">
        <Navbar setOpenHelpDialog={setOpenHelpDialog} setOpenNotifications={setOpenNotifications}/>
        <div className='settings-container'>
        {
          urlParam && urlParam.settingPath==='main' ? 
          <>
          {
            SettingsData.map((item,index) => (
              <div className="settings-page-item" key={index}>
                <p className="settings-title">{item.title}</p>
                <p className="settings-subtitle">{item.subtitle}</p>
                <div className="settings-list">
                {
                  item.settings.map((setting,i) => (
                    <div key={i} className="settings-list-item" onClick={() => handleSettingClick(setting.name,setting.path)}>
                      {/* <img src={require(`./svgs/${setting.img}`).default} alt={setting.name}/> */}
                      <p>{setting.name}</p>
                    </div>
                  ))
                }
                </div>
              </div>
            ))
          }
          </>
          :
          <>
            <div className="setting-main-detailed-container">
              <div className="setting-main-detailed-list">
              <p className="settings-main-title" onClick={() => navigate('/settings/main')}>Settings</p>
              {
                SettingsData.map((item,index) => (
                  <Fragment key={index}>
                    <p className="setting-list-title">{item.title}</p>
                    {
                      item.settings.map((setting,i) => (
                        <p key={i} onClick={()=>handleSettingClick(setting.name,setting.path)} className={`setting-list-item ${urlParam.settingPath === setting.path ?'selected':''}`}>{setting.name}</p>
                      ))
                    }
                  </Fragment>
                ))
              }            
              </div>
              <div className="setting-main-detailed-content">
                <p className="settings-main-title greyish">{selectedSettingTitle}</p>
                {SettingsMicroPages[urlParam.settingPath]}
              </div>
            </div>
          </>
        }
        </div>
      </div>
      <HelpSideNavbar helpOpen={openHelpDialog} closeSideBar={setOpenHelpDialog} />
      <NotificationBar
        filterOption
        showNotify={openNotifications}
        closeNotify={setOpenNotifications}
        listOfNotification={[]}
      />
    </div>
  )
}
export default Settings;