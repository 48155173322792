import './phone-number-header.style.scss';

const PhoneNumberHeader = ({number,onSave}) => {
  return(
    <div className='phone-number-header'>
      <div className='phone-number-header-content'>
        <p className='phone-number-header-number'>
          {number}
        </p>
        <p className='phone-number-header-detail'>
          Settings related to this phone number
        </p>
      </div>  
      <div onClick={() => onSave()} className='phone-number-header-button'>
        <p>Save</p>
      </div>
    </div>
  )
} 
export default PhoneNumberHeader;