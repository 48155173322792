import React from "react"
import "./input.scss"

const InputBox = ({
    placeHolder,
    complete,
    type,
    name,
    value,
    changeFunc,
    req,
    brdr,
    SrcIcon,
    focus,
    onFocus,
    onBlur,
    reverse,
    clickFun,
    ind,
}) => {
    const FocusRef = React.useRef([null])

    const handleFocus = () => {
        const next = FocusRef.current[0]
        if (next) {
            next.focus()
        }
    }
    return (
        <div
            className={`inputBox ${focus === ind ? "actBox" : ""}`}
            style={{
                borderColor: brdr ? brdr : null,
                flexDirection: reverse ? "row-reverse" : "row",
            }}
            onFocus={handleFocus}
            //   onBlur={onBlur}
            onFocusCapture={handleFocus}
            onClick={handleFocus}
        >
            <div
                className="icon"
                onClick={(e) => (clickFun ? clickFun() : e.preventDefault())}
            >
                {
                    SrcIcon ? <SrcIcon className="icon-svg"/> : <div className="icon-svg" />
                }
            </div>
            <div className={`inn`}>
                <div className="label">{placeHolder}</div>
                <input
                    ref={(el) => (FocusRef.current[0] = el)}
                    autoComplete={`${complete}`}
                    type={type}
                    name={name}
                    value={value}
                    onChange={(e) => changeFunc(e.target.value)}
                    required={req}
                    autoFocus={ind === focus ? true : false}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </div>
        </div>
    )
}

export default InputBox
