import { useState } from "react";
import { updateNavBarMode,updateTheme } from "../../../store/ux-slice";
import { useSelector,useDispatch } from "react-redux";
import "./settings-component.scss";
import {ReactComponent as Light} from "./assets/sun.svg";
import {ReactComponent as Dark} from "./assets/moon.svg";
import RoundSwitch from "../../../components/round-switch/round-switch.component";

/**
 * The Setting Sub Page : Customising Looks and UI of the Web App
 * @returns {HTMLElement}
 */
const Customise = () => {
  const dispatch = useDispatch();
  const default_theme = useSelector((state) => state.ux.theme)
  const default_nav_bar = useSelector((state) => state.ux.nav_bar_mode);
  const [theme,setTheme] = useState(default_theme==="DARK");
  const [nav,setNav] = useState(default_nav_bar==="TOP");

  const toggleTheme = () => {
    if (theme) {
      dispatch(updateTheme("LIGHT"));
    }
    else {
      dispatch(updateTheme("DARK"));
    }
    setTheme(!theme);
  }
  const toggleNav = () => {
    if (nav) {
      dispatch(updateNavBarMode("SIDE"));
    }
    else {
      dispatch(updateNavBarMode("TOP"));
    }
    setNav(!nav);
  }
  return(
    <div className="setting-component-container">
      <p className="setting-component-title">Theme</p>
      <div className="setting-component-item">
        <div className="setting-switch-left">
          <Light />
        </div>
        
        <RoundSwitch theme={theme} setTheme={toggleTheme}/>
        <div className="setting-switch-left right">
          <Dark className="moon-svg"/>
        </div>
        
      </div>
      <p className="setting-component-title">Nav Bar Style</p>
      <div className="setting-component-item">
        <div className="setting-switch-left">
          <p>Side Nav</p>
        </div>
        <RoundSwitch theme={nav} setTheme={toggleNav}/>
        <div className="setting-switch-left right">
          <p>Top Nav</p>
        </div>
        
      </div>
    </div>
  )
}
export default Customise;