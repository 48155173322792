import { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import Sidebar from "../../components/sidebar/sidebar.component";
import Navbar from "../../components/navbar/navbar.component";
import ContactDetail from "../../pagelets/contact-detail/contact-detail.pagelet";
import ContactInfo from "../../components/contact-info/contact-info.component";
import "./contact-main.page.scss";
import NewContactList from "../../pagelets/new-contact-list/new-contact-list.pagelet";
import HelpSideNavbar from "../../Awesome-ui/HelpSideNavbar/HelpSideNavbar";
import { getDataByUniqueID } from "../../services/cassandra-services";
import { updateToast } from "../../store/toast-slice";
import { CodeAnalogy } from "../../components/toast/toast.component";
import NotificationBar from "../../Awesome-ui/NotificationBar/NotificationBar";


/**
 * Contact Main is a specific contact page of an individual
 * @returns {HTMLElement}
 */
const ContactMain = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let contactParam = useParams();

  const token = useSelector((state) => state.user.token);
  const nav_bar_mode = useSelector((state) => state.ux.nav_bar_mode);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [contactDetailData, setContactDetailData] = useState();

  useEffect(() => {
    if (token) {
      if (contactParam.contactUniqueId) {   // To avoid unnecessary API call when "/list/:listUnqiue" call or "/newlist"
        const individual_contact = getDataByUniqueID(contactParam.contactUniqueId);
        const printPromiseJSON = async () => {
          const a = await individual_contact;
          setContactDetailData(a.data.model);
        };
        printPromiseJSON();
      }
    }
    else {
      navigate('/signin');
      dispatch(updateToast({
        code: CodeAnalogy.WARN, 
        title: "Please login to proceed!",
        message: ""
      }));
    }
  },[]);

  return(
    <div className="contact-main-container">
      {nav_bar_mode!=="TOP" ? <Sidebar /> : null}
      <div className="contact-content-container">
      <Navbar setOpenHelpDialog={setOpenHelpDialog} setOpenNotifications={setOpenNotifications}/>
        {
          location.pathname==="/contacts/newlist" ? 
          <NewContactList /> : null
        }
        {
          location.pathname===`/contacts/lists/${contactParam.listUniqueId}` ? 
          <NewContactList 
            existingListId = {contactParam.listUniqueId}
          /> : null
        }
        {
          location.pathname===`/contacts/${contactParam.contactUniqueId}` && contactDetailData ?
          <>
            <div className="content-container">
              <ContactInfo data={contactDetailData}/>
              <ContactDetail data={contactDetailData} optionsList={optionsList}/>
            </div>
          </> : null
        }
      </div>
      <HelpSideNavbar helpOpen={openHelpDialog} closeSideBar={setOpenHelpDialog} />
      <NotificationBar
        filterOption
        showNotify={openNotifications}
        closeNotify={setOpenNotifications}
        listOfNotification={[]}
      />
    </div>
  )
}
export default ContactMain;
const optionsList = [
  {
    option_id: 0,
    name: "Contact Details",
  },
  {
    option_id: 1,
    name: "Subscribed List",
  },
  {
    option_id: 2,
    name: "Segments",
  },
  {
    option_id: 3,
    name: "Forms",
  },
  {
    option_id: 4,
    name: "Automation",
  },
  {
    option_id: 5,
    name: "Purchases",
  },
]