import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, PERSIST,REGISTER } from 'redux-persist';

import cassandraReducer from './cassandra-slice';
import subscriptionReducer from './subscription-slice';
import toastReducer from './toast-slice';
import userReducer from "./user-slice";
import uxReducer from './ux-slice';

const reducers = combineReducers({
  cassandra: cassandraReducer,
  subscription: subscriptionReducer,
  user: userReducer,
  ux: uxReducer,
  toast: toastReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user','ux']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
})