import React from "react"
import "./AppSwitcher.scss"
import { useSelector } from "react-redux"
import DrawerElementWrapper from "../DrawerElementWrapper"
// import { ReactComponent as SignIcon } from "Assets/ProductIcon/signicon.svg"
import { productMap } from "../../helper/products";

/**
 * main app switcher component
 * @param {*} param0
 * @returns
 */
const AppSwitcher = ({ openAppSwitcher = true, setOpenAppSwitcher }) => {
    const subscription = useSelector((state) => state.subscription.subscription);

    if (!openAppSwitcher) {
        return null
    }
    return (
        <div className="app-switcher">  
            <div className="app-switcher-container">  
                <div className="app-switcher-cross" onClick={() => setOpenAppSwitcher(false)}><p>+</p></div>
                <div className="switcher-top-bar">Applications</div>
                <div className="switcher-body">
                    <DrawerElementWrapper title="My Apps">
                        {subscription &&
                            subscription.map((item) => (
                                <AppContent {...productMap[item.productId]} />
                            ))}
                    </DrawerElementWrapper>
                    <DrawerElementWrapper
                        title="My Apps"
                        compStyle={{ marginTop: "auto" }}
                        style={{ flexDirection: "column" }}
                    >
                        {Object.keys(productMap).map((item) => {
                            const find =
                                subscription &&
                                subscription.find((pr) => pr.productId === item)
                            console.log(find); 
                            if (find) return null
                            return <AppContent {...productMap[item]} url={"https://awesomesuite.com"} try />
                        })}
                    </DrawerElementWrapper>
                </div>
            </div> 
        </div>
    )
}

export default AppSwitcher

const AppContent = ({ style, tryIt, name, description, Icon, url }) => {
    return (
        <a className="aui-app-card" href={url}>
            <div className="app-card-icon">{Icon}</div>
            <div className="">
                <div className="app-name">{name}</div>
                <p>{description}</p>
                {tryIt && <div className="app-try">Try</div>}
            </div>
        </a>
    )
}
