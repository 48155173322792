import {Link} from 'react-router-dom';
import "./dev-tools.scss";

const DevTools = () => {
  return(
    <div className="dev-tools">
      <div className='dev-item'>
        <p>ROUTE LINKS</p>
        <Link to="/">
        <button className="dev-button">default</button></Link>
        <Link to="/settings/main">
        <button className="dev-button">/settings</button></Link>
        <Link to="/contacts">
        <button className="dev-button">/contacts</button></Link>
        <Link to="/contacts/newlist">
        <button className="dev-button">/contacts/newlist</button></Link>
        <Link to="/getting-started">
        <button className="dev-button">/getting-started</button></Link>
      </div>
    </div>
  )
}
export default DevTools;