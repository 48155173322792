const NameNumber = ({number, location, price, active, onClickEvent}) => {
  return(
    <>
      <div className={`number-price-card ${active ? 'active-price':''}`} onClick={() => onClickEvent()}>
        <div className="number-price-left">
          <p className="number-price-title">
          {number}
          </p>
          <p className="number-price-subtitle">
          {location}
          </p>
        </div>
        <p className="number-price-tag">
        {price}
        </p>
      </div>
    </>
  )
}
export default NameNumber;