import { useState, useEffect } from 'react';
import ContactBodyAllContacts from '../contact-body/contact-body-all-contacts.pagelet';
import ContactBodyLists from '../contact-body/contact-body-lists.pagelet';
import ContactBodySegment from '../contact-body/contact-body-segment.pagelet';
import ContactHeader from '../../components/contact-header/contact-header.component';
import './contacts.pagelet.scss'

/**
 * Contacts: Pagelet containing all features of Contact Screen
 * @returns {HTMLElement}
 */
const Contacts = () => {
  
  useEffect(() => {
    document.title = "Contacts | Awesome Send"
    // This is for updating title of page
  },[]);

  const [option, setOption] = useState(0)
  return(
    <div className="contacts-container">
      <ContactHeader option={option} setOption={setOption} optionsList={optionsList}/>
      <div className="contact-body">
      {
        option===0?<ContactBodyAllContacts /> : null
      }
      {
        option===1?<ContactBodyLists /> : null
      }
      {
        option===2?<ContactBodySegment /> : null
      }
      
      </div>
    </div>
  )
}
export default Contacts;
const optionsList = [
  {
    option_id: 0,
    name: "All Contacts",
  },
  {
    option_id: 1,
    name: "Lists",
  },
  {
    option_id: 2,
    name: "Segments",
  },
]