import { Avatar } from '@awesomesuite-frontend/awesome-nebula';
import React, {useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserSideBar from '../../Awesome-ui/UserSideBar';
import { updateToast } from '../../store/toast-slice';
import { revokeBearerToken } from '../../store/user-slice';
import { updateNavBarMode, updateTheme } from '../../store/ux-slice';
import CreateMenu from '../create-menu/create-menu.component';
import { CodeAnalogy } from '../toast/toast.component';
import './right-navbar-buttons.component.scss'

/**
 * Navbar buttons on right side
 * @returns {HTMLElement}
 */
const RightNavbarButtons = React.memo(({setOpenNotifications,setOpenHelpDialog}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [showUserDropDown, setShowUserDropDown] = useState(false)
  const userDetails = useSelector((state) => state.user.profile_details);
  const theme = useSelector((state) => state.ux.theme);
  const nav_bar_mode = useSelector((state) => state.ux.nav_bar_mode);

  const handleClick = () => {
    setShowMenu(!showMenu);
  }
  const handleOpenHelpDialogClick = () => {
    setOpenHelpDialog(true);
  }
  const handleOpenNotificationsDialogClick = () => {
    setOpenNotifications(true);
  }
  const handleNavigateTo = (path) => {
    navigate(path);
  }
  const handleNavBarMode = (value) => {
    dispatch(updateNavBarMode(value))
  }
  const handleTheme = (value) => {
    dispatch(updateTheme(value))
  }
  const handleLogOut = () => {
    dispatch(revokeBearerToken());
    dispatch(updateToast({
      code: CodeAnalogy.SUCCESS, 
      title: "Logged out successfully.",
      message: ""
    }));
    navigate('/signin');
  }
  return(
    <div className="right-navbar-buttons">
      <div className="create-div">
        <div className="create-icon" onClick={()=>handleClick()}>
          <p>+</p>
        </div>
        {
          showMenu ? 
          <div className="dialog-box">
            <CreateMenu setShowMenu={setShowMenu}/>
          </div>
          : null
        }
        <p className="create-text">Create</p>
      </div>
      <div className="empty-div" onClick={() => handleNavigateTo('/settings/main')}>

      </div>
      <div className="empty-div" onClick={() => handleOpenHelpDialogClick()}>

      </div>
      <div className="empty-div" onClick={() => handleOpenNotificationsDialogClick()}>

      </div>
      <div className="user-detail-div">
        <div style={{border: "1px solid white", borderRadius:"50%"}} onClick={() => setShowUserDropDown(true)}>
        <Avatar 
          size="xs" 
          imageSource="https://pbs.twimg.com/profile_images/1503591435324563456/foUrqiEw_400x400.jpg" 
        />
        </div>
        <UserSideBar 
          showUserDropDown={showUserDropDown}
          setShowUserDropDown={setShowUserDropDown}
          userDetails={userDetails}
          signOut={handleLogOut}
          sideBarType={nav_bar_mode}
          setSideBarType={handleNavBarMode}
          sideBarColor={theme}
          setSideBarColor={handleTheme}
        />
      </div>
    </div>
  )
})
export default RightNavbarButtons;