import ExcelSheetCol from '../excel-sheet-col/excel-sheet-col.component';
import './excel-sheet.component.scss';

/**
 * Basic Excel Sheet Generator - For Contact List Page
 * @returns {HTMLElement}
 */
const ExcelSheet = ({data}) => {
  console.log("Excel Data: ",data)
  let rowNumber = 28;
  if (data.length > 0) {
    rowNumber = data.length;
  }
  const generateIndexCell = () => {
    let indexList = [];
    indexList.push(
      <div key={"empty"} className='index-cell empty' />
    )
    for (let row=0; row < rowNumber; row++) {
      indexList.push(
        <div key={row} className='index-cell'>
          <p className='cell-text'>{row+1}</p>
        </div>
      )
    }
    return indexList;
  }
  let indexList = generateIndexCell();
  return(
    <div className='excel-sheet-container'>
      <div className='excel-index'>
      {
        indexList
      }
      </div>
      <ExcelSheetCol rowNumber={rowNumber} data={data}/>
    </div>
  )
}
export default ExcelSheet;