import './button.component.scss';

/**
 * Button - Pink Themed Button Component as found in SignUp and SignIn Page
 * @param {Function} handleClick
 * @param {String} children 
 * @returns {HTMLElement}
 */
const Button = ({handleClick, children}) => {
  return(
    <div className="button-container" onClick={()=>handleClick()}>
      <p className="button-text">{children}</p>
    </div>
  )
}
export default Button;