import Contacts from "../pagelets/contacts/contacts.pagelet";
import GettingStarted from "../pagelets/getting-started/getting-started.pagelet";
import HomePage from "../pagelets/homepage/homepage.pagelet";

/**
 * All Dashboard Pagelets would be directed from here
 * TODO: Make this also applicable to Route Declaration in App.js
 */
export const NavigationOptions = {
  "/": <HomePage />,
  "/contacts": <Contacts />,
  "/getting-started": <GettingStarted />,
}

export const NavigationOptionsList = [
  {
    name: "Dashboard",
    path: "/"
  },
  {
    name: "Contacts",
    path: "/contacts"
  },
  {
    name: "Send",
    path: "/send"
  },
  {
    name: "Automation",
    path: "/automation"
  },
  {
    name: "Forms",
    path: "/forms"
  },
  {
    name: "Library",
    path: "/library"
  },
  {
    name: "Reports",
    path: "/reports"
  },
];