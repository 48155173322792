import "./blank-input-box.component.scss";

/**
 * This Input donot have any placeholder
 * @param {Boolean} large
 * @param {String} value : Hook State
 * @param {Function} setValue
 * @returns {HTMLElement}
 */
const BlankInputBox = ({large,value,setValue}) => {
  return(
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)} 
      className={`blank-input-box ${large ? 'large' : ''}`}/>
  )
}
export default BlankInputBox;